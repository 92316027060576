$black: #212121;
$grey: #555555;
$lightgrey: #888888;
$white: #ffffff;

$red: #db3739;
$orange: #eea541;
$green: #a2c754;
$purple: #816c98;
$blue: #53beec;

$mc: $red;
// $org: $orange;
$org: $red;
// $req: $blue;
$req: $red;
// $user: $green;
$user: $red;
// $act: $purple;
$act: $red;
$tc: $black;

$headerHeight: 80px;

$radius: 3px;
$bsDark: 0 0 30px -2px rgba($black, 0.2);
$bsLight: 0 0 30px -2px rgba($black, 0.1);
$bsSmallerItem: 0 0 8px -2px rgba($black, 0.15);
$bsBox: -8px 6px 20px -10px;
$border: 1px solid rgb(215, 215, 215);

$fontTitle: 'Montserrat', sans-serif;
$font: 'Roboto', sans-serif;
