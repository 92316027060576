@media print {
    body {
        background: none !important;
    }

    .container {
        max-width: none !important;
    }

    .general-header {
        display: none !important;
    }

    .form-box {
        margin-left: 0 !important;
        width: 100% !important;
        box-shadow: none !important;
        padding: 0 !important;
    }

    .button, .buttons-container {
        display: none !important;
    }

    footer {
        display: none !important;
    }

    // DASHBOARD
    .dashboard-main-nav, .stats-filters {
        display: none !important;
    }

    .stats-page h1 {
        margin-top: 20px !important;
        @include icon {
            display: none !important;
        }
    }

    .stats-graphs section {
        margin-top: 0 !important;
        page-break-inside: avoid;
    }

    .rv-xy-plot__grid-lines {
        opacity: 0.1;
    }

    .horiz-histo-section-wrap {
        page-break-inside: avoid;
    }

    .grouped-horiz-histo-wrap > div {
        top: 0 !important;
    }
}
