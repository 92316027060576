.dashboard-wrap {
    .dashboard-main-nav {
        width: 100%;
        margin-bottom: 40px;
        margin-top: -20px;

        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        background: rgba($lightgrey, 0.1);

        @include mobile {
            flex-flow: column;
            margin-top: 0;
        }

        a {
            display: flex;
            justify-content: center;
            align-items: center;

            width: 33.33333%;
            padding: 10px 4px;

            text-align: center;
            font-family: $fontTitle;
            font-weight: 700;
            font-size: 16px;
            color: $lightgrey;

            @include mobile {
                width: 100%;
            }

            &.active {
                color: $mc;
                background: lighten($mc, 40);
                opacity: 1 !important;
            }

            @include icon {
                font-size: 18px;
                margin-right: 10px;
            }
        }
    }

    h1 {}
}
