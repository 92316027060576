.status-page {

    .status-filters {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;

        margin-bottom: 40px;

        .ant-select-selection--multiple {
            padding-bottom: 0;
        }

        .ctn:nth-of-type(1) {
            width: 340px;
        }

        .ctn:nth-of-type(2) {
            width: calc(100% - 20px - 340px - 140px);
        }

        .ctn:nth-of-type(3) {
            width: 140px;
        }

        .ant-btn {
            margin-top: 10px;
            margin-left: auto;
        }

        @include mobile {
            flex-flow: column;

            .ctn {
                width: 100% !important;
                margin-bottom: 10px;
            }
        }
    }

    .status-graph {
        min-width: 1000px;
        position: relative;
    }

    $orgWidth: 200px;
    $lineHeight: 45px;

    @mixin artefact-list {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-around;
        padding: ($lineHeight - 35px)/2 0;
        // > * { width: 25%; }

        @include icon {
            font-size: 16px;
            border-radius: 50%;
            width: 25px;
            height: 25px;
            line-height: 25px;
            border: none !important;
        }
    }

    .status-header {
        display: flex;
        flex: row nowrap;
        padding: 12px 0 6px;
        margin-bottom: 8px;
        border-bottom: 4px solid $mc;
        border-top: 4px solid $mc;
        background: rgba($mc, 0.1);

        .header-buffer {
            width: $orgWidth;
            font-family: $fontTitle;
            color: rgba($tc, 0.6);
            font-weight: 700;
            font-size: 12px;
            padding-left: 10px;

            @include icon {
                left: 40px !important;
            }
        }

        .header-buffer, .section-main-title {
            position: relative;
            cursor: pointer;

            @include icon {
                position: absolute;
                left: 50%; top: -10px;
                transform: translateX(-50%);
                font-size: 14px;
            }
        }

        .header-sections {
            width: calc(100% - #{ $orgWidth });
            display: flex;
            flex-flow: row nowrap;

            .section {
                text-align: center;

                .section-main-title {
                    font-size: 14px;
                }

                &-analysis {
                    width: 150px !important;

                    .section-artefacts {
                        @include artefact-list;
                        padding-bottom: 0;

                        @include icon {
                            color: rgba(darken($mc, 20), 0.6);
                            background: rgba(darken($mc, 20), 0.1);
                        }
                    }
                }
            }
        }
    }

    .status-body {
        position: relative;

        .status-line {
            position: absolute;
            left: 0; right: 0;
            // transform: translateY(-50%);

            display: flex;
            flex-flow: row nowrap;

            height: $lineHeight;

            .status-line-title {
                display: flex;
                flex-flow: column;
                justify-content: center;

                * {
                   display: inline-block;
                   width: $orgWidth;

                   overflow: hidden;
                   white-space: nowrap;
                   text-overflow: ellipsis;
                   padding-right: 10px;
                   padding-left: 10px;

                   font-family: $fontTitle;
                   color: $lightgrey;
               }

               span {
                   font-size: 12px;
                   font-weight: 700;
                   text-decoration: underline;
               }

               small {
                   font-size: 10px;
                   font-weight: 300;
               }
            }

            .status-line-sections {
                width: calc(100% - #{ $orgWidth });
                display: flex;
                flex-flow: nowrap;

                .section {
                    text-align: center;
                    position: relative;

                    @include icon {
                        opacity: 1 !important;
                        font-size: 20px;
                        color: rgba(darken($mc, 20), 0.6);
                        background: rgba(darken($mc, 20), 0.1);
                        border-radius: 50%;
                        width: 35px;
                        height: 35px;
                        line-height: 31px;
                        box-shadow: $bsSmallerItem;
                        margin: ($lineHeight - 35px)/2 0;
                        border: 2px solid transparent;

                        &.white {
                            background: $white;
                            color: $grey;
                            border-color: $grey;
                        }

                        &.red {
                            background: lighten($red, 35);
                            color: $red;
                            border-color: $red;
                        }

                        &.green {
                            background: lighten($green, 35);
                            color: $green;
                            border-color: $green;
                        }
                    }


                    &:after {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 50%; left: 0; right: 100%;
                        transform: translateY(-50%);
                        height: 20px;
                        background: rgba($green, 1);
                        z-index: -1;

                        transition: all 0.2s linear;
                    }

                    @for $i from 0 through 15 {
                        &:nth-child(#{$i}):after {
                            transition-delay: 0.2s * ($i - 1);
                        }

                    }

                    &:first-child:after { left: 50% !important; right: 50%; }

                    &-analysis {
                        width: 150px !important;

                        > a { display: none; }

                        .section-artefacts {
                            height: 35px;
                            border: 2px solid transparent;
                            margin: ($lineHeight - 35px)/2 0;
                            border-radius: 200px;

                            @include artefact-list;

                            @include icon {
                                box-shadow: none;
                                background: none !important;
                            }
                        }

                        .white + .section-artefacts {
                            background: $white;
                            color: $grey;
                            border-color: $grey;
                        }

                        .red + .section-artefacts {
                            background: lighten($red, 35);
                            color: $red;
                            border-color: $red;
                        }

                        .green + .section-artefacts {
                            background: lighten($green, 35);
                            color: $green;
                            border-color: $green;
                        }
                    }
                }

                @for $i from 0 through 15 {
                    &.state-#{$i}.animate {
                        @for $j from 1 through 16 {
                            :nth-child(#{$j}):after {
                                @if ($i + 1 < $j) {
                                    right: 100%;
                                } @elseif ($i + 1 == $j) {
                                    right: 50%;
                                } @else {
                                    right: 0;
                                }
                            }
                        }
                    }
                }

                &.state-0 :nth-child(1):after {
                    right: 50% !important;
                }

                &.complete.animate :last-child:after { right: 50% !important; }
            }
        }
    }

    .status-body-error {
        display: none;
        font-family: $fontTitle;
        color: $lightgrey;
        font-size: 18px;
        font-weight: 700;
        text-align: center;
        padding: 50px 10px 30px;
    }
}
