
body, input, button, textarea {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: $font;
    font-weight: 400;
}

h1, h2, h3, .title {
    font-family: $fontTitle;
}

h1 {
    font-size: 60px;
    font-weight: 700;
    line-height: 1.2;

    margin-bottom: 20px;

    color: $grey;

    display: inline-block;

    @include dashed-line($mc);
    &:after {
        bottom: 9px;
        left: -20px;
    }

    @include mobile {
        font-size: 56px;
    }
}

h2 {
    font-size: 24px;
    font-weight: 700;

    color: $mc;
}

h3 {
    font-size: 32px;
    font-weight: 700;

    color: $lightgrey;

    margin-bottom: 30px;
}

h2, h3 {
    &.title-link {
        transition: all 0.2s ease;
        cursor: pointer;

        font-size: 28px;
        text-decoration: underline;

        display: inline-block;

        &:hover {
            opacity: 0.8;
        }
    }
}

a {
    color: $mc;
    text-decoration: none;
    transition: all 0.2s ease;
    cursor: pointer;

    &:hover, &:active {
        color: $mc;
        opacity: 0.8;
    }
}

i {
    font-style: normal;
}

b {
    font-weight: 700;
}
