// DATATABLE
#root {
    .reload-filters-btn {
        cursor: pointer;
        transition: all 0.2s ease;

        font-family: $fontTitle;
        font-size: 14px;
        font-weight: 900;
        color: $mc;

        display: flex;
        align-items: center;

        position: absolute;
        top: 25px;
        left: 30px;
        z-index: 25;

        @include icon {
            margin-right: 10px;
            font-size: 16px;
        }

        i {
            text-decoration: underline;
        }

        &:hover {
            opacity: 0.8;
        }

        @include mobile {
            padding: 30px 20px 0;
            position: static;
            text-align: right;
            display: block;
        }
    }

    h3 + .reload-filters-btn {
        position: static;
        float: right;
        padding: 0 30px;
        margin-top: 45px;

        @include mobile {
            margin-top: 0;
            padding: 20px;
            float: none;
        }
    }

    .MuiPaper-elevation2 {
        box-shadow: none;
        background: none;

        font-family: $font;
    }

    .MuiToolbar-root {
        @include icon {
            font-size: 22px;
            &.main {
                font-size: 28px;
                color: $mc;
            }
        }
    }

    @at-root .MuiFormControlLabel-root {
        pointer-events: none;
    }

    @at-root .MuiPopover-paper {
        width: auto !important;
    }

    .MuiTableHead-root {
        .MuiTableSortLabel-icon {
            font-size: 14px;
            font-weight: 700;
            text-align: center;

            width: 18px;
            height: 18px;
            line-height: 18px;
        }

        .MuiTableCell-root {
            font-family: $fontTitle;
            font-weight: 700;
            font-size: 12px;

            color: $grey;

            // min-height: 40px;
            padding-bottom: 8px;
            padding-top: 6px;
            vertical-align: bottom;

            &:before {
                font-size: 8px;
                padding-right: 8px;
                margin-left: -16px;
            }
        }
    }

    .MuiTableBody-root {
        .MuiTableRow-root {
            transition: all 0.2s ease;
            cursor: pointer;

            // background: $white;
            // box-shadow: 0 0 8px -2px rgba($black, 0.15);
            // border-radius: $radius;
            // border: none !important;

            // padding-right: 10px;
            // margin-bottom: 8px;

            position: relative;

            .MuiTableCell-root {
                // border: none;
                font-size: 14px !important;
            }

            @include icon {
                color: $lightgrey;
            }

            &:nth-child(2n + 3) {
                background: rgba($lightgrey, 0.05);
            }

            &:not(:first-child):hover {
                background: rgba($lightgrey, 0.2);
            }

            &:first-child {
                cursor: default;
                background: rgba($mc, 0.2);

                .MuiTableCell-root {
                    padding-top: 8px;
                    padding-bottom: 8px;
                }

                .MuiInput-root {
                    border: 1px solid $lightgrey;
                    background: $white;

                    height: 30px;

                    &:before, &:after {
                        display: none !important;
                    }

                    @include icon {
                        font-size: 14px;
                        font-weight: 700;

                        margin-left: 4px;
                        // margin-right: 2px;
                    }
                }

                label + .MuiInput-formControl {
                    margin-top: 0;
                }

                input,
                .MuiSelect-selectMenu {
                    font-size: 12px;
                    font-weight: 700;

                    border: none;
                    padding: 0;
                }

                .MuiSelect-selectMenu {
                    padding-right: 25px;
                    padding-left: 6px;
                }
            }

            &:empty {
                display: none;
            }
        }
    }

    .MuiTableFooter-root {
        .MuiTableCell-root {
            border: none;
        }
    }
}
