.general-header {
    height: $headerHeight;
    margin-top: 10px;
    margin-bottom: 60px;

    position: relative;
    z-index: 999;

    @include mobile {
        height: auto;
        // margin-bottom: 40px;
    }

    nav {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: flex-start;

        @include mobile {
            flex-flow: column;
        }
    }

    a {
        font-family: $fontTitle;
        font-weight: 700;
        font-size: 14px;

        height: 40px;
        line-height: 40px;
        padding: 0 12px;

        display: inline-block;
    }

    .home-link {
        float: left;
        margin-right: 24px;
        padding-left: 0;
        height: $headerHeight;

        img {
            height: $headerHeight;
            padding: 8px 0;
        }
    }

    .primary-links {
        height: $headerHeight;

        display: flex;
        flex-flow: row wrap;
        align-items: center;

        @include mobile {
            margin-top: 20px;
        }

         > a {
            color: $lightgrey;

            padding: 0 16px;
            display: flex;
            align-items: center;

            @include icon {
                font-size: 16px;
                margin-right: 8px;
            }

            @include mobile {
                height: 30px;
                line-height: 30px;
                padding: 0;
                margin-right: 20px;
                margin-bottom: 10px;
            }

            position: relative;

            &:after {
                content: '';

                background: $lightgrey;
                transition: all 0.2s ease;

                width: 0;
                height: 3px;

                display: block;

                position: absolute;
                left: 46px;
                bottom: 0;

                @include mobile {
                    left: 26px;
                    bottom: -6px;
                }
            }

            &:hover {
                // opacity: 1;
                &:after { width: 20px; }
            }

            &.active, &:hover.active {
                opacity: 1;

                &:after {
                    width: 20px;
                    background: $mc;
                }
            }
        }

        @include not-mobile {
            > a.has-subroutes {
                &:hover {
                    opacity: 1;


                    .subroutes {
                        display: flex;
                    }

                    &:after {
                        width: 100%;
                        left: 0;
                        // bottom: 0;
                    }
                }

                .subroutes {
                    background: $white;
                    border-radius: $radius;
                    box-shadow: $bsLight;

                    padding-top: $headerHeight - 4px;
                    padding-bottom: 12px;
                    display: none;
                    flex-flow: column nowrap;

                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 8px;
                    z-index: -1;

                    a {
                        transition: all 0.2s ease;
                        font-size: 12px;
                        color: $lightgrey;

                        height: auto;
                        line-height: 1.4;
                        padding: 8px 12px 8px 20px;

                        &:hover {
                            opacity: 0.8;
                        }
                    }
                }
            }
        }
    }

    .secondary-links {
        margin-left: auto;

        height: $headerHeight;
        display: flex;
        align-items: center;

        @include mobile {
            position: absolute;
            top: 0;
            right: 20px;
        }

        .user-link {
            font-size: 12px;
            height: auto;
            line-height: 1.4;

            small {
                font-size: 10px;
                font-weight: 400;

                color: $lightgrey;

                margin-top: 4px;
                line-height: 1;

                display: block;
            }
        }

        .logout-button {
            color: $grey;
            padding-right: 0;

            @include icon {
                font-size: 18px;
                font-weight: 700;
            }
        }
    }

}
