// DATATABLE
// FILTRES ET EN HAUT DU TABLEAU
// Catégories (légende) -- voir categories.scss

// Styles des popups (filtres / colonnes à afficher)
.popup-box {
    background: $white;
    border: $border;
    box-shadow: $bsLight;

    padding: 10px;
    width: 250px;

    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;

    position: absolute;
    top: 50px;
    right: 0;
    z-index: 98;

    @include mobile {
        padding: 20px !important;
    }

    &.show {
        display: flex;
    }

    &.hide {
        display: none;
    }

    &:before {
        content: '';
        display: block;
        position: absolute;
        top: -22px;
        right: 20px;
        background: $mc;
        width: 2px;
        height: 22px;
    }

    @include mobile {
        width: calc(100% + 20px + 20px) !important;
        right: -20px;

        &:before {
            right: 40px;
        }
    }
}

// Les 2 niveaux de filtres
.first-level-options-table,
.second-level-options-table {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @include mobile {
        flex-flow: column nowrap;
        justify-content: flex-end;
        align-items: flex-end;
    }

    // Boutons dans les 2 niveaux
    a {
        height: 30px;
        padding-left: 12px;
        padding-right: 12px;

        display: flex;
        align-items: center;

        i {
            margin: 0 6px;
        }

        @include icon {
            font-size: 12px;
        }
    }
}

// 1er niveau
.first-level-options-table {
    margin-bottom: 20px;

    @include mobile {
        margin-top: 20px;
        margin-bottom: 8px;
        flex-flow: column-reverse;
    }
}

// 2e niveau
.second-level-options-table {
    margin-bottom: 8px;
}

// 1er niveau : Navigation entre type de vue
.first-level-options-table .table-buttons {
    display: none !important;
    
    font-size: 12px;
    margin-left: 16px;
    display: flex;

    @include mobile {
        width: 100%;
        margin-left: 0;

        @include icon { display: none; }
    }

    // Boutons des types de vue
    a {
        font-size: 12px;

        &:last-of-type {
            border-left: none;
        }

        @include mobile {
            width: 50%;
            justify-content: center;

            i { margin: 0; }
        }
    }
}

// 1er niveau : Filtres
.filters-block {
    position: relative;

    @include mobile {
        width: 100%;
        margin-top: 8px;
    }

    // Bouton des filtres
    a {
        font-size: 12px;
        margin-left: 28px;

        @include mobile {
            width: 100%;
            margin: 0;
            justify-content: center;
        }
    }

    // Formulaire de filtres (popup)
    form {
        background: $white;
        border: $border;
        box-shadow: $bsLight;

        padding: 10px 20px 10px 10px;
        width: 360px;

        flex-flow: column nowrap;
        align-items: flex-start;

        position: absolute;

        // Label
        label {
            font-size: 12px;
            width: 90px;
        }

        // Champs textes
        input {
            font-size: 12px;

            padding: 2px 4px;
            width: 234px;

            @include mobile {
                width: 100%;
            }
        }

        // Override des styles pour les dropdowns
        .ant-select {
            font-size: 12px;
            font-weight: 700;

            width: 234px;

            @include mobile {
                width: 100%;
            }

            .ant-select-selection {
                border-radius: 0;
                border: none;
                border-bottom: 2px solid $tc;
                outline: none;
                box-shadow: none;
            }

            &.ant-select-focused .ant-select-selection {
                border-color: $mc;
            }

            .ant-select-selection__choice {
                font-size: 12px;
                color: $lightgrey;
                background: rgba($lightgrey, 0.24);
                border: none;
                border-radius: 0;

                height: 22px;
                line-height: 22px;
            }
        }

        // Boutons du formulaire
        .form-buttons {
            margin-top: 16px;

            display: flex;
            align-self: flex-end;
        }

        button, .button {
            font-size: 12px;

            line-height: 20px;

            display: flex;
            align-items: center;

            @include icon {
                font-size: 12px;
            }
        }
    }
}

// Override des styles pour les dropdowns (2e partie)
.ant-select-dropdown-menu-item  {
    font-size: 12px;
}

.ant-select-search__field {
    padding: 0 !important;
    font-weight: 700;
}

// 2e niveau : Colonnes à afficher
.columns-display {
    position: relative;

    @include mobile {
        width: 100%;
    }

    // Bouton des colonnes à afficher
    a {
        font-size: 12px;

        @include mobile {
            width: 100%;
            justify-content: center;
        }
    }

    // List des colonnes (popup)
    ul {
        li {
            width: 110px;
            font-size: 12px;

            @include mobile {
                width: 50%;
            }

            label {
                font-size: 12px;
                font-weight: 500;

                margin-left: 8px;
                margin-right: 0;
            }
        }
    }
}
