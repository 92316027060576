.stats-page {
    position: relative;

    h1 {
        position: relative;

        @include icon {
            position: absolute;
            top: 55%; right: -50px;
            transform: translateY(-50%);
            cursor: pointer;
            transition: all 0.2s ease;

            &:hover {
                opacity: 0.8;
            }

            @include mobile {
                display: none;
            }
        }
    }
}

.stats-filters {
    width: 100%;
    // max-width: 500px;
    margin: 0 auto;
    padding-bottom: 0 !important;

    h2 {
        position: relative;
        z-index: 11;

        font-size: 16px;
        color: $white;
        background: rgba($mc, 1);

        display: flex;
        align-items: center;
        padding: 10px 10px;

        cursor: pointer;
        transition: all 0.2s ease;

        @include icon {
            font-size: 18px;
            margin-left: auto;
        }

        b {
            background: $white;
            color: $mc;
            font-size: 12px;
            font-weight: 900;

            display: block;
            width: 20px;
            height: 20px;
            line-height: 20px;
            text-align: center;
            border-radius: 50%;
            margin-right: 10px;
        }
    }

    .filters-wrap {
        position: absolute;
        z-index: 10;
        top: 0;

        overflow: hidden;
        padding: 0 10px;
        max-height: 0;
        width: 100%;

        background: $white;
        box-shadow: $bsLight;
        border-radius: $radius;

        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;

        @include mobile {
            flex-flow: column wrap;
        }

        &.opened {
            max-height: 10000px;
            padding-top: 70px;
        }

        > * {
            width: calc(50% - 20px);
            &:last-child { margin-bottom: 10px; }

            @include mobile {
                width: 100%;
                margin-bottom: 10px;
            }

            .ctn, .ant-radio-group {
                width: 100%;
                margin-bottom: 20px;

                label {
                    display: block;
                    font-size: 12px;
                    font-weight: 700;
                }

                .ant-input,
                .ant-select {
                    display: block;
                }

                .ant-radio + span {
                    margin-left: 10px;
                    color: $lightgrey;
                }
            }
        }
    }

    .filters-buttons {
        width: 100% !important;

        display: flex;
        justify-content: flex-end;

        margin-top: 20px;
    }
}

.stats-graphs {
    section {
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
        justify-content: space-between;

        &:not(:first-of-type) {
            margin-top: 40px;
        }

        @include mobile {
            flex-flow: column wrap;
        }

        .section-title {
            width: 100%;
            text-align: center;
            font-size: 36px;
            background: rgba($mc, 0.1);
            color: $mc;
            padding: 10px;
            line-height: 1.4;
        }
    }

    .graph-item {
        padding: 30px 0;

        h2 {
            margin-bottom: 10px;
            // font-size: 24px;
            line-height: 1.4;

            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding: 10px 10px;
            // color: $grey;

            background: rgba($lightgrey, 0.1);

            @include icon {
                &:first-child {
                    margin-right: 20px;
                }

                &:last-child {
                    display: block;
                    font-size: 16px;
                    cursor: pointer;
                    margin-left: 25px;
                    color: $lightgrey;
                }
            }

            @at-root {
                .ant-tooltip-inner {
                    font-size: 12px;
                    min-height: 24px;
                }
            }
        }

        &.centered {
            text-align: center;
        }

        &.upper-item {
            border: 4px solid rgba($lightgrey, 0.1);
            background: rgba($lightgrey, 0.05);
            // margin-bottom: 20px;

            h2 {
                justify-content: center;
                background: none;
                padding: 0;
            }

            .error {
                padding: 0 !important;
                background: none !important;
            }

            * {
                color: $lightgrey;
                margin: 0;
            }
        }

        &.unavailable-graph {
            line-height: 1.4;

            .error {
                display: flex;
                flex-flow: column;
                align-items: center;
                justify-content: center;
                text-align: center;

                padding: 30px 20px;
                background: rgba($lightgrey, 0.1);

                @include icon {
                    font-size: 42px;
                    margin-bottom: 10px;
                    color: $lightgrey;
                }
            }

            h3 {
                font-size: 16px;
                margin-bottom: 6px;
            }

            p {
                font-size: 10px;
            }
        }

        width: 100%;
        @include not-mobile {
            &.col-1 { width: calc(25% - 20px); }
            &.col-2 { width: calc(50% - 20px); }
            &.col-3 { width: calc(75% - 20px); }
            &.col-4 { width: 100%; }
        }
    }
}
