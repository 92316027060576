.general-footer {
    margin-top: 80px;

    .container {
        border-top: 1px solid $lightgrey;
        padding-top: 20px;
        padding-bottom: 20px;

        display: flex;
        justify-content: space-between;
    }

    small {
        font-size: 12px;
        font-weight: 700;
        font-family: $fontTitle;

        color: $lightgrey;

        .separator {
            font-weight: 400;
            font-family: $font;
            display: inline-block;
            margin: 0 12px;
        }
    }
}
