.profile-styles {
    .buttons:not(:empty) {
        display: flex;
        justify-content: flex-end;
    }

    .profile-main-content {
        li:not(:last-of-type) {
            margin-bottom: 16px;
        }

        &.read label {
            text-align: right;
            width: 100px;
            margin-right: 4px;

            @include mobile {
                width: 100%;
                text-align: left;
                margin-right: 0;
            }
        }

        &.read.larger {
            li {
                display: flex;
                flex-flow: row wrap;
                justify-content: flex-start;
            }

            label {
                width: 260px;

                @include mobile {
                    width: 100%;
                }
            }

            b {
                width: calc(100% - 264px);

                @include mobile {
                    width: 100%;
                }
            }
        }

        .note-content,
        .note-file {
            label {
                width: auto;
                text-align: left;
            }

            label + * {
                display: block;
            }

            textarea {
                min-width: 100%;
                max-width: 100%;
            }
        }

        &:not(.read) .note-file {
            margin-top: 40px;
        }

        .note-file {
            > span {
                display: flex;
                flex-flow: column;

                span {
                    font-size: 14px;
                    color: $grey;

                    // margin-left: 24px;
                    margin-top: 8px;

                    &:first-of-type {
                        margin-top: 2px;
                    }

                    b, input {
                        font-size: 14px;
                        color: $tc;
                        display: inline-block;
                    }

                    input {
                        margin-left: 4px;
                    }
                }
            }

            > small {
                margin-left: 24px;
                margin-top: 8px;
            }

            i {
                color: $lightgrey;
            }
        }

        .checkboxes {
            display: flex;

            > * {
                &:not(:last-of-type) {
                    margin-right: 20px;
                }

                .ant-checkbox {
                    margin-right: 4px;

                    + span {
                        font-size: 14px;
                        font-weight: 700;
                    }
                }
            }
        }
    }

    .profile-main-content + .buttons {
        padding-top: 20px;
    }

    .tags {
        margin-bottom: 26px;

        display: flex;

        li {
            @include tag-item;
        }
    }
}
