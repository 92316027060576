$colors: (
    organisations: $org,
    requests: $req,
    users: $user,
    activity: $act,
);

@each $class, $c in $colors {
    .#{ $class }-color {
        // Attributs spécifiés
        .text-fill,
        &.text-fill {
            color: $c !important;
        }

        .bg-fill,
        &.bg-fill {
            background: rgba($c, 1) !important;
        }

        .bg-opacity-fill,
        &.bg-opacity-fill {
            background: rgba($c, 0.1) !important;
        }

        .border-fill,
        &.border-fill {
            border-color: $c !important;
            &:after { background: $c !important; }
        }

        // Items génériques qu'on target sur toutes les pages
        h1 {
            @include dashed-line($c);
            &:after {
                bottom: 9px;
                left: -20px;
            }
        }

        h2 {
            color: $c;
        }

        .box {
            box-shadow: $bsBox rgba($c, 0.3);
        }

        form button, .button {
            background: $c;
        }

        .rdt_TableRow:after {
            background: $c;
        }

        .back-button {
            color: $c;
        }
    }

}
