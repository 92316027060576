.home-page {
    h2.switcher {
        margin-top: 30px;

        display: inline-block;
        float: right;

        a {
            font-size: 18px;
            color: $lightgrey;

            margin-right: 20px;

            &.active {
                color: $mc;
            }
        }

        @include mobile {
            margin-top: 0;
            margin-bottom: 10px;

            display: block;
            float: none;
        }
    }
}
