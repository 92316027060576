.login-page {
    min-height: 100vh;

    display: flex;
    flex-flow: row nowrap;

    // Bloc de gauche
    .left-block {
        background: $white;
        box-shadow: $bsDark;

        width: 50%;
        min-width: 300px;
        padding: 80px 0 40px;

        display: flex;
        align-items: center;

        position: relative;
        z-index: 2;

        @include mobile {
            width: 100%;
        }
    }

    // Block de droite
    .right-block {
        background: rgba($mc, 0.92);

        width: 50%;

        position: relative;
        z-index: 1;

        @include mobile {
            display: none;
        }

        img {
            opacity: 0.1;

            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
        }
    }
}

.login-form {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;

    display: flex;
    flex-flow: column wrap;
    align-items: center;

    // Contenant
    > div {
        padding: 20px;
        width: 100%;

        display: flex;
        flex-flow: column nowrap;
    }

    // Formulaire
    .error,
    .warning {
        color: $mc;
        font-weight: 700;
        text-align: right;
        margin-bottom: 14px;
    }

    .warning {
        color: $orange;
    }

    // Submit
    .buttons {
        margin-top: 6px;

        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    // Logo
    img {
        height: 60px;
        margin-top: 60px;
    }
}

.reset {
    header {
        align-self: flex-start;
    }

    .left-block {
        width: 100%;
    }

    .login-form {
        max-width: 500px;

        > div {
            padding-left: 0;
            padding-right: 0;
        }

        .text-conf {
            margin-top: -8px;
            font-size: 18px;
            line-height: 1.6;
            display: inline-block;
        }
    }
}
