.reporting-page {
    .buttons {
        display: flex;
        justify-content: flex-end;

        margin-top: 20px;
    }

    .table-box {
        margin-top: 40px;

        .MuiTableCell-root {
            // vertical-align: bottom !important;
        }
    }

    .error {
        font-family: $fontTitle;
        font-size: 20px;
        font-weight: 700;
        text-align: center;

        color: $lightgrey;

        padding: 40px 30px;
    }

    .ant-skeleton {
        padding: 20px 30px;
    }
}
