.request-details {
    // DOCUMENTS
    @mixin docs {
        ul {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
        }

        a {
            color: $tc;
        }

        $h: 48px;

        li:not(.small),
        li.small a,
        li.small.not-done {
            width: calc(50% - 10px);
            margin-bottom: 8px;

            font-size: 14px;
            transition: all 0.2s ease;

            background: $white;
            box-shadow: $bsSmallerItem;
            border-radius: $radius;
            border: none !important;

            padding: 0;
            display: block;

            max-height: $h;
            overflow: hidden;

            @include mobile {
                width: 100%;
            }

            > * {
                 padding: calc(#{ $h } / 12) calc(#{ $h } / 2);
            }

            .file-item-header {
                // cursor: pointer;

                height: $h;

                display: flex;
                align-items: center;
                justify-content: flex-start;

                @include icon {
                    &:first-of-type {
                        font-size: 16px;
                        font-weight: 700;

                        color: $white;
                        background: $red;
                        border-radius: $radius;
                        text-align: center;

                        display: block;
                        width: 24px;
                        height: 24px;
                        line-height: 24px;
                        margin-right: 18px;

                        &.done {
                            background: $green;
                        }
                    }

                    &:last-of-type {
                        margin-left: auto;
                        font-size: 18px;
                        color: $grey;

                        display: none;
                    }

                }
            }

            .file-item-body {
                height: 100%;
                background: rgba($lightgrey, 0.1);
                display: flex;
                flex-flow: column;
                padding: 16px calc(#{ $h } / 2 + 18px + 6px) 16px calc(#{ $h } / 2 + 24px + 18px);

                i {
                    font-size: 12px;
                    font-weight: 700;
                    color: $grey;
                    margin-bottom: 6px;
                }

                > span {
                    display: flex;
                    flex-flow: column wrap;

                    font-size: 12px;
                    color: $grey;

                    margin-top: 8px;

                    b, input {
                        font-size: 12px;
                        color: $tc;
                        margin-top: 2px;
                        display: inline-block;
                    }
                }
            }

            &.opened {
                max-height: 1000px;

                @include icon {
                    &:last-of-type { transform: rotate(180deg); }
                }
            }
        }
    }

    article.documents-box {
        @include docs;

        h3 + small {
            font-size: 14px;
            color: $lightgrey;

            display: inline-block;
            padding-left: 3px;
            transform: translateY(-30px);
            margin-bottom: 5px;
        }
    }

    @at-root .request-documents-modal {
        @include docs;

         li a,
         li {
             width: 100% !important;
         }

         .done a {
             background: rgba($green, 0.1) !important;
             box-shadow: none !important;
         }

         .not-done {
             background: rgba($red, 0.1) !important;
             box-shadow: none !important;
         }

        .ant-modal-footer {
            display: none;
        }
    }


    // STATUS BAR
    .status-bar {
        padding-top: 20px;
        margin-bottom: 20px;

        .split-block {
            width: 100%;

            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;

            margin-right: 20px;
            margin-bottom: 20px;

            @include mobile {
                flex-flow: column-reverse;
                align-items: flex-start;
            }
        }

        article.buttons {
            margin-bottom: 20px;

            .button {
                justify-content: center;
                text-align: center;
            }
        }
    }

    .timeline {
        min-width: 300px;

        @include mobile {
            padding-top: 10px;
        }

        * {
            font-family: $font;
        }

        .ant-timeline {
            transform: translateY(15px);
        }

        .greyed {
            .ant-timeline-item-head,
            .ant-timeline-item-content {
                color: rgba($lightgrey, 0.6) !important;
                border-color: rgba($lightgrey, 0.6) !important;
            }
        }
    }

    .box.notification {
        font-size: 14px;

        background: rgba($mc, 0.1);
        box-shadow: none;

        border-radius: $radius;
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 20px 30px;

        a {
            font-weight: 700;
        }
    }

    .status-box {
        width: 100%;
        margin-left: 40px;
        margin-bottom: 0;

        .buttons {
            margin-top: 10px;

            display: flex;
            justify-content: flex-end;
        }

        .assignees {
            li {
                display: flex;

                &:not(:first-of-type) {
                    margin-top: 10px;
                }

                @include mobile {
                    display: block;
                }
            }

            label {
                margin-right: 8px;
                width: 160px;
                text-align: right;

                display: flex;
                flex-flow: column;
                justify-content: center;
                align-items: flex-end;

                margin-bottom: 0;

                i {
                    font-size: 14px;
                }

                small {
                    font-size: 12px;
                    color: $lightgrey;
                }

                @include mobile {
                    width: 100%;
                    text-align: left;
                    align-items: flex-start;
                    margin-bottom: 6px;
                }
            }

            b {
                margin-top: -2px;
            }

            .ant-select {
                .ant-select-selection-selected-value { font-size: 14px; }
                width: calc(100% - 168px);

                @include mobile {
                    width: 100%;
                }
            }

            .button {
                font-size: 14px;
            }
        }
    }

    .buttons.footer {
        margin-top: 20px;
        margin-bottom: -40px;
        padding: 10px 0 12px;
        border-top: 1px solid rgba($lightgrey, 0.24);

        @include mobile {
            flex-flow: row wrap;

            .button {
                width: 100%;
                margin: 6px 0;
                justify-content: center;
            }
        }
    }

    // ANALYSES
    .analyzes-buttons {
        padding-top: 5px;
        margin-bottom: 30px;
        margin-top: -20px;

        .button {
            font-size: 14px;
        }
    }

    .analyzes-box h3 {
        margin-bottom: 10px;
    }

    .analyzes-list {
        display: flex;
        // padding-top: 20px;

        @include mobile {
            flex-flow: column;
        }

        .analyze-item {
            background: rgba($mc, 0.1);
            box-shadow: none;

            border-radius: $radius;
            width: 100%;
            margin-bottom: 0;
            padding: 30px 30px 20px;
            // box-shadow: $bsSmallerItem;

            display: flex;
            flex-flow: column;

            @include mobile {
                margin-bottom: 10px;
            }

            &:not(:last-of-type) {
                margin-right: 20px;
            }

            h4 {
                font-size: 16px;
                font-weight: 700;
                font-family: $fontTitle;
                text-decoration: underline;

                margin-bottom: 10px;

                @include mobile {
                    text-align: center;
                }

                a { color: $mc; }
            }

            .button {
                font-size: 12px;
                margin-top: auto;
                justify-content: center;
            }

            &.green {
                background: rgba(darken($green, 10), 0.1);
                h4 a { color: $green; }
            }

            &.grey {
                background: rgba(darken($lightgrey, 10), 0.1);
                h4 a { color: $lightgrey; }
            }

            &:empty {
                display: none;
            }
        }
    }

    // RECOMMANDATIONS
    .recommendations-box {
        li {
            margin-bottom: 16px;
        }

        .doc {
            display: flex;
            flex-flow: column;

            > span {
                font-size: 14px;
                color: $grey;

                // margin-left: 24px;
                margin-top: 8px;

                &:first-of-type {
                    margin-top: 2px;
                }

                b, input {
                    font-size: 14px;
                    color: $tc;
                    display: inline-block;
                }

                input {
                    margin-left: 4px;
                }
            }

            > small {
                margin-left: 24px;
                margin-top: 8px;
            }
        }

        .buttons {
            margin-top: 10px;
            display: flex;
            justify-content: flex-end;
        }

        &.read-only {
            label {
                margin-right: 4px;
                margin-bottom: 0;
            }

            i {
                color: $lightgrey;
            }

            .doc {
                flex-flow: row;
            }
        }
    }

    // LINKS
    .help-links {
        margin-top: 30px;
        padding: 40px 30px;
        border: 2px solid rgba($lightgrey, 0.1);
        background: rgba($white, 0.24);
        border-radius: 20px;

        h4 {
            font-size: 24px;
            font-family: $fontTitle;
            font-weight: 700;

            color: rgba($lightgrey, 0.6);

            margin-bottom: 10px;
        }

        .links {
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-start;
            align-items: stretch;

            @include mobile {
                flex-flow: column;
            }
        }

        a {
            width: calc(33% - 5px);
            padding: 10px 20px;
            margin-bottom: 10px;

            &:not(:nth-of-type(3n)) {
                margin-right: 10px;
            }

            @include mobile {
                width: 100%;
                margin-right: 0 !important;
            }
        }
    }
}

.doc-popup {
    font-size: 16px;
    font-weight: 700;
    font-family: $fontTitle;

    color: $white;
    background: rgba($mc, 1);
    border-radius: $radius;
    box-shadow: 0 0 15px 6px rgba(33, 33, 33, 0.2);

    padding: 10px 20px;

    display: flex;
    align-items: center;
    justify-content: center;

    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 10;

    @include icon {
        font-size: 20px;
        margin-right: 8px;
    }

    &:after {
        content: '';
        display: block;
        background: rgba($mc, 0.24);
        border-radius: $radius + $radius;

        position: absolute;
        top: -6px;
        bottom: -6px;
        left: -6px;
        right: -6px;
        z-index: -1;
    }

    &:hover {
        opacity: 1;
        transform: translateY(-2px);
        box-shadow: 0 0 20px 6px rgba(33, 33, 33, 0.22);
        color: $white;
    }
}

// CONSENT
.request-presubmit-modal {
    input[type="text"] {
        width: 100px;
        text-align: center;
        margin-top: 4px;
        margin-bottom: 12px;
        // border: none;
    }
}

.request-approbation-modal {
    .ctn {
        margin-bottom: 16px;
    }
}


// CREATION
.create-batch-modal {
    width: 700px !important;

    .ctn {
        margin-bottom: 16px;
    }

    @at-root .ctn.file {
        > span, input {
            display: block;
        }

        small {
            font-size: 10px;
            display: inline-block;
            margin-top: 6px;
        }

        margin-bottom: 28px;
    }

    .ant-transfer {

    }

    .ant-transfer-list-body-search-wrapper {
        padding: 4px 12px;
        background: rgba($lightgrey, 0.05);
        border-bottom: 1px solid rgba($lightgrey, 0.24);

        input[type="text"] {
            font-size: 12px;
            padding: 4px 10px;
            height: 28px;
            line-height: 28px;
            border-color: rgba($lightgrey, 0.24);
        }

        .ant-transfer-list-search-action {
            top: 4px;
            bottom: 4px;
        }
    }

    .ant-transfer-list {
        width: 298px;
        height: 300px;
        padding-top: 36px;

        .ant-checkbox-wrapper {
            margin-right: 4px;
            margin-bottom: 0;
        }

        .ant-transfer-list-header {
            font-size: 12px;
            height: 36px;

            .ant-transfer-list-header-selected {
                font-weight: 700;
                transform: translateY(-1px);
                display: inline-block;
            }
        }

        .ant-transfer-list-body {
            padding-top: 36px;
        }
    }

    .ant-transfer-operation {
        width: 40px;

        button {
            width: 40px;
            padding: 6px 0;
        }
    }
}
