.faq-wrap {
    display: flex;
    flex-flow: row-reverse;
    justify-content: space-between;
    align-items: flex-start;

    .faq-main-box {
        width: calc(65%);

        li:not(:last-of-type) {
            border-bottom: 1px solid rgba($lightgrey, 0.24);
        }

        .title-qa {
            cursor: pointer;
            transition: all 0.2s ease;

            font-family: $fontTitle;
            font-weight: 700;

            padding: 20px 0;

            display: flex;
            align-items: center;

            @include icon {
                font-size: 18px;
                color: $lightgrey;
                margin-right: 10px;
            }

            &:hover {
                opacity: 0.8;
            }
        }

        .content-qa {
            padding: 2px 0 20px 28px;
            margin-top: -10px;

            br {
                content: ' ';
                display: block;
                margin: 12px 0 0;
            }
        }
    }

    .faq-second-box {
        width: 35%;
        padding: 30px 20px;

        @include mobile {
            padding-top: 10px;
        }

        p:first-of-type {
            margin-bottom: 12px;
            font-family: $fontTitle;
            color: $grey;
        }

        p:last-of-type, ul {
            font-size: 14px;
            color: $lightgrey;
        }

        ul {
            margin-top: 16px;

            li {
                margin-bottom: 12px;
                font-size: 12px;
            }

            b {
                display: block;
                font-size: 14px;
                margin-top: 4px;

                small {
                    font-size: 12px;
                    margin-top: 2px;
                    display: inline-block;
                }
            }
        }
    }

    @include mobile {
        flex-flow: column;

        article {
            width: 100% !important;
        }
    }
}
