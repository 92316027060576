section.note__section {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 1em;
    article {
        width: 100%;
        a {
            height: 45px;
        }
        button {
            justify-content: center;
            height: 45px;
            margin-bottom: 10px;
            span {
                position: absolute;
                left: 85%;
            }
            &.button {
                background-color: $grey;
            }
            &.button .material-icons {
                font-size: 25px;
            }
        }
    }
}
