$border: darken($req, 30);
$alt: rgba($lightgrey, 0.1);

$thead: rgba($req, 0.4);
$thead2: lighten($req, 30);
$thead3: lighten($lightgrey, 30);
$linetitle: rgba($req, 0.05);

body[data-financial="true"] {

    h1 {
        // margin-bottom: 36px;

        small {
           font-size: 14px;
           position: absolute;
           left: 2px;
           bottom: -12px;
       }
    }

    .top-link {
        left: 70px;

        &:before {
            content: '/';
            display: inline-block;
            margin: 0 20px;
            pointer-events: none;
        }
    }

    #root > .container {
        width: 100% !important;
        max-width: none !important;
        padding: 0 !important;

        > div .container {
            @include clearfix;

            &:last-of-type .button {
                margin-bottom: 0;
            }
        }
    }

    .buttons-container {
        margin-top: 20px;
        margin-bottom: 20px;
        width: 800px;
        margin-left: auto;
        margin-right: auto;

        > b {
            font-size: 16px;
            color: $grey;
            text-align: center;

            display: inline-block;
            width: 100%;
            margin-bottom: 10px;
        }

        .wrap {
            display: flex;
            flex-flow: column;

            > div {
                display: flex;
                flex-flow: row nowrap;
                justify-content: center;
                align-items: center;

                margin-bottom: 10px;
            }

            b {
                font-family: $fontTitle;
                font-size: 14px;
                text-align: right;
                color: $lightgrey;

                margin-right: 10px;
                width: 60px;
            }

            .button-link {
                margin: 0;
                float: none;

                font-size: 14px;
                padding: 5px 15px;

                background: rgba($mc, 0.1);
                color: $mc;

                &:not(:last-of-type) {
                    margin-right: 10px;
                }

                &.validate {
                    background: rgba($green, 0.24);
                    color: $green;
                }
            }
        }
    }

    .form-box.financial,
    .form-box.financial-summary {
        width: 100%;
        margin: 0;
    }

    .top-select {
        margin-bottom: 12px;

        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;

        span {
            font-size: 14px;
            font-weight: 700;

            margin-right: 4px;
        }

        .ant-select {
            font-size: 14px;
            width: 90px;
        }
    }

    .button {
        width: auto;
        float: right;
        margin-top: 20px;
        margin-bottom: 20px;
        margin-left: 20px;
    }

    .financial .table-header,
    .financial-summary .table-header {
        margin-bottom: 20px;

        min-width: 800px;
        margin-left: auto;
        margin-right: auto;

        td {
            @include icon {
                font-size: 14px;
                margin-left: 6px;
                transform: translateY(3px);
            }
        }

        tbody td input {
            width: 100% !important;
            max-width: auto;
        }

        tbody tr {
            td {
                border-bottom: 1px solid rgba($lightgrey, 0.24);
            }

            td.not-first {
                background: none !important;
            }
        }

        tr.dates {
            td:not(:first-of-type) {
                text-align: center;
                font-size: 12px !important;
                font-weight: 700;
                background: $thead;
                border-bottom: 1px solid $border;
            }

            td {
                border-top: 2px solid $border;
            }

            + tr td {
                font-weight: 700;
                font-size: 12px !important;
                text-align: center;
                color: $tc !important;
            }
        }
    }

    .form-box.financial,
    .form-box.financial-summary {
        max-width: 1360px;
        margin: 20px auto 0;
        padding: 0 20px;

        thead {
            tr {
                background: $thead;
            }

            th[colspan="2"][rowspan="1"] {
                border: 2px solid $border;
            }

            tr:last-of-type th {
                font-size: 12px;
                opacity: 0.6;
                padding: 0px 8px 4px;
            }
        }

        tbody {
            th {
                border: 2px solid $border;
                color: $border;
                background: darken($thead2, 20);
                font-weight: 700;
                font-size: 16px;
                font-family: $fontTitle;
            }

            td {
                // vertical-align: bottom;
            }

            tr {
                // border-bottom: 2px solid $border;
                background: $white;
            }

            tr.financial-section-name {
                background: $thead3;
                font-size: 12px;
                font-weight: 700;
                text-align: center;
                color: $grey;
                border: 2px solid $border;

                td {
                    padding: 14px 0;
                }
            }

            tr.financial-subsection-name {
                background: $alt;
                color: $grey;
                border: 2px solid $border;

                td {
                    border: none;
                    font-size: 12px;
                    font-weight: 700;
                    padding-top: 8px;
                    padding-bottom: 8px;
                }
            }

            tr.financial-subsection-total {
                background: lighten($thead2, 5);

                td {
                    font-size: 12px !important;
                    padding-top: 8px;
                    padding-bottom: 8px;
                    vertical-align: bottom;

                    input {
                        font-size: 12px !important;
                    }
                }
            }

            tr.financial-section-total {
                border: 2px solid;

                td {
                    padding-top: 8px;
                    padding-bottom: 8px;
                    background: $thead !important;
                    vertical-align: bottom;
                }
            }

            tr.total-tilte {
                border-top: 4px solid $border;
                border-bottom: 4px solid $border;

                th {
                    // background: $thead;
                }
            }

            tr.financial-total {
                td {
                    vertical-align: middle !important;
                    background: $alt;
                }
            }

            tr.financial-total:last-of-type td {
                border-top: 2px solid $border;
                text-align: right !important;
            }

            tr:not(.financial-section-name):not(.financial-subsection-name) {
                td:first-of-type {
                    background: $thead2;
                    color: $border;
                    text-align: center;
                    font-size: 12px;
                    font-weight: 700;
                    vertical-align: middle !important;
                }

                td:nth-of-type(2) {
                    font-size: 14px;
                    font-weight: 700;
                }

                td:not(:first-of-type):not(:nth-of-type(2)) {
                    vertical-align: bottom;
                }
            }

            input {
                width: 200px !important;

                &[disabled] {
                    background: none !important;
                    border: none !important;
                    padding: 0 !important;
                    font-weight: 700;
                }
            }
        }


        td, th {
            border-right: 2px solid $border;
        }
    }

    .form-box.financial.read-only,
    .form-box.financial-summary {
        input,
        td span {
            font-size: 14px !important;

            display: inline-block;
            width: 120px !important;
            min-width: 120px !important;
            max-width: 120px !important;

            text-align: right !important;
            vertical-align: middle !important;
        }

        .financial-total,
        .financial-subsection-total,
        .financial-section-total,
        .financial-element {
            td:not(:nth-of-type(2)),
            td:not(:nth-of-type(2)) input {
                text-align: right !important;
                vertical-align: middle !important;
            }

            border-bottom: 1px solid rgba($lightgrey, 0.24);

            // td:not(:nth-of-type(1)):not(:nth-of-type(2)) {
            //     span, input {
            //         &:not(:empty):after {
            //             content: ' $'
            //         }
            //     }
            // }
        }
    }

    .form-box.financial-summary {
        table {
            background: rgba($lightgrey, 0.24);
            border: 2px solid $border;
        }

        td {
            font-weight: 400 !important;
        }

        .row-total {
            td {
                font-weight: 700 !important;
            }

            td.cell-title:first-of-type {
                text-align: right !important;
            }
        }
        .row-data,
        .row-total {
            td { border-bottom: 1px solid rgba($lightgrey, 0.24); }

            td:first-of-type {
                text-align: left !important;
                vertical-align: middle !important;
                min-width: 200px;
                border-color: rgba($border, 0.24);
            }

            td {
                text-align: right !important;
                vertical-align: middle !important;
            }

            td:nth-of-type(7),
            td:nth-of-type(8),
            td:nth-of-type(9) {
                background: rgba($orange, 0.24);
            }

            &.first-lines,
            &.second-lines {
                td:nth-of-type(4),
                td:nth-of-type(5),
                td:nth-of-type(6) {
                    background: rgba($green, 0.24);
                }
            }

            &.soldes {
                td:nth-of-type(5),
                td:nth-of-type(6) {
                    background: $tc;
                }
            }

            &.others {
                td:nth-of-type(5),
                td:nth-of-type(6) {
                    background: $tc;
                }
            }
        }

        .row-total.first-lines td:not(:first-of-type) {
            background: rgba($orange, 0.24) !important;
        }

        .row-total.soldes td,
        .row-total.others:last-of-type td {
            background: rgba($orange, 0.24);
        }
    }
}
