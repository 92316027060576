$navwidth: 300px;


// Informations du haut
.form-infos {
    margin-top: 20px;

    float: right;
    text-align: right;

    > * {
        font-family: $fontTitle;
        color: $grey;

        display: block;
    }

    .date {
        font-size: 16px;
    }

    .status {
        font-size: 14px;
        font-weight: 400;

        margin-bottom: 10px;
    }

    @include mobile {
        margin-top: 0;
        text-align: left;
        float: none;

        * {
            display: inline-block;
        }

        .date {
            margin-right: 20px;
        }
    }
}

.form-infos + .infos {
    padding-right: 80px;
}

// Barre de progrès
.form-progress-wrap {
    position: relative;

    .percentage {
        font-family: $fontTitle;
        font-size: 20px;
        color: $req;

        position: absolute;
        top: -48px;
        right: 0;

        @include mobile {
            position: static;
            margin-top: 10px;
            width: 100px;
            text-align: right;
        }
    }

    @include mobile {
        display: flex;
        flex-flow: row-reverse;
    }
}
.form-progress {
    background: $white;
    border-radius: $radius;
    box-shadow: $bsLight;

    margin-top: 20px;
    margin-bottom: 40px;
    width: 100%;
    height: 8px;

    position: relative;

    .fill {
        transition: all 0.2s ease;
        background: $req;
        border: 4px solid lighten($req, 15);
        border-radius: $radius;

        min-width: 15px;
        height: 200%;

        position: absolute;
        top: -4px;
    }
}

// Formulaire général
.form-wrap {
    @include clearfix;
    display: flex;
    justify-content: flex-start;

    form {
        @include clearfix;
        width: 100%;

         > * { float: left; }
    }
}

// Navigation de gauche
.form-nav {
    margin-top: 60px;
    width: $navwidth;

    position: sticky;
    top: 60px;

    @include mobile {
        margin-top: 20px;
        width: 100%;

        position: relative;
        top: 0;
    }

    // Boutons
    .print-btn {
        margin-top: 60px;
        display: inline-block;

        @include mobile {
            margin-top: 20px;
        }
    }

    > button, .print-btn {
        @include mobile {
            float: right;
            clear: both;
        }

        &.save {
            margin-top: 10px;
        }
    }

    // Texte de sauvegarde
    small {
        color: $lightgrey;

        margin-top: 20px;

        display: flex;

        @include mobile {
            margin-top: 20px;
            clear: both;
            float: right;

            flex-flow: row-reverse;
        }

        @include icon {
            text-align: center;

            width: 36px;
            height: 36px;
            line-height: 36px;
        }

        i {
            font-size: 14px;
            font-weight: 400;

            margin-left: 12px;

            display: inline-block;

            @include mobile {
                text-align: right;

                margin-right: 12px;
                margin-left: 0;
            }
        }

        time {
            font-weight: 700;
            font-family: $fontTitle;

            display: block;

            &::first-letter {
                text-transform: uppercase;
            }
        }
    }

    // Liens de nav
    li {
        font-size: 14px;
        font-weight: 700;
        font-family: $fontTitle;

        color: $grey;
        cursor: pointer;
        transition: all 0.2s ease;

        margin-bottom: 16px;

        display: flex;
        align-items: center;

        button {
            text-align: left;

            color: $grey;
            background: none;

            padding: 0;

            &:focus {
                outline: none;
            }
        }

        i {
            margin-left: 12px;
        }

        @include icon {
            font-size: 18px;
            text-align: center;

            color: $white;
            background: $grey;

            border: 4px solid lighten($grey, 40);
            border-radius: 50%;

            line-height: 28px;
            width: 36px;
            height: 36px;
        }


        &:hover {
            opacity: 0.8;
        }

        &.active {
            button { color: $req !important; }

            @include icon {
                background: $req !important;
                border-color: lighten($req, 15) !important;
            }

            &:hover {
                opacity: 1;
            }
        }

        &.done {
            @include icon {
                background: $green;
                border-color: lighten($green, 15);
            }
        }

        &.error {
            @include icon {
                background: $red;
                border-color: lighten($red, 15);
            }
        }
    }
}

// Formulaire en soi
.form-box {
    @include clearfix;

    margin-left: 20px;
    width: calc(100% - #{$navwidth} - 20px);

    @include mobile {
        margin-left: 0;
        margin-top: 40px;
        width: 100%;
    }

    h2 {
        margin-bottom: 12px;

        display: flex;
        align-items: center;

        @include icon {
            font-size: 24px;
            font-weight: 700;

            margin-right: 12px;
        }
    }

    h3 {
        font-size: 20px;

        margin-top: 40px;
        margin-bottom: 20px;

        &.important {
            font-size: 14px;
            color: $mc;
        }
    }

    .section-descr,
    .financial-link {
        font-size: 14px;
        color: $grey;
        margin-bottom: 30px;

        a {
            margin-top: 8px;
            font-weight: 700;
        }
    }

    .section-descr + h3 {
        margin-top: 0;
    }


    // Inputs et labels
    button {
        float: right;
    }

    label {
        @include help-icon;
    }

    section:not([data-section-id='1']) p:not(.section-descr),
    section:not([data-section-id='1']) .ctn {
        margin-bottom: 28px;
    }

    small.char-count {
        font-size: 12px;
        color: $lightgrey;
        margin-top: 4px;
    }

    .radio-wrap,
    .checkbox-wrap {
        margin-bottom: 6px;

        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        label { margin-bottom: 0; }
    }

    .formula {
        $i1: 25%;
        $i2: 50%;
        $i3: 25%;
        $op: 20px;

        label {
            margin-bottom: 12px;
        }

        input {
            text-align: center;
        }

        .help-formula,
        .upper-formula,
        .lower-formula {
            width: 100%;
            display: flex;
            align-items: center;

            i {
                font-size: 14px;
                font-weight: 700;
                text-align: center;

                display: inline-block;
                width: $op;
            }
        }

        .help-formula {
            font-size: 12px;
            font-weight: 700;

            color: $grey;
            text-align: center;

            span {
                display: inline-block;
            }

            span:nth-of-type(1) {
                width: calc(#{ $i1 } - #{ $op });
            }

            span:nth-of-type(2),
            span:nth-of-type(3) {
                width: calc(#{ $i2 / 2 } - #{ $op / 2 });
                color: $lightgrey;
            }

            span:nth-of-type(4) {
                width: calc(#{ $i1 } - #{ $op } - #{ $op });
            }
        }

        .upper-formula {
            justify-content: center;
            margin-bottom: 6px;

            input {
                width: calc(#{ $i2 / 2 } - #{ $op / 2 });
            }
        }

        .lower-formula {
            input:nth-of-type(1) {
                width: calc(#{ $i1 } - #{ $op });
            }

            input:nth-of-type(2) {
                width: $i2;
            }

            input:nth-of-type(3) {
                width: calc(#{ $i1 } - #{ $op } - #{ $op });
            }
        }
    }

    .table-wrap {
        position: relative;
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        display: block;
        overflow-x: scroll;
    }

    // File
    .file-type {
        > span {
            font-size: 14px;
            color: $grey;

            margin-left: 24px;
            margin-top: 8px;

            b, input {
                font-size: 16px;
                color: $tc;

                margin-left: 8px;

                display: inline-block;
            }
        }

        > small {
            margin-left: 24px;
            margin-top: 8px;
        }
    }

    // Table
    $border: darken($req, 30);
    $alt: rgba($lightgrey, 0.1);

    $thead: rgba($req, 0.4);
    $thead2: lighten($req, 30);
    $linetitle: rgba($req, 0.05);

    table {
        min-width: 100%;
        margin-top: 6px;
        border: 2px solid $border;

        thead {
            font-size: 14px;
            font-weight: 700;

            color: $border;
            background: $thead;
            border: 2px solid $border;

            line-height: 1.2;

            th {
                // border-right: 1px solid $border;
                padding: 8px 8px;
                vertical-align: middle;
            }
        }

        th::first-letter {
            text-transform: uppercase;
        }

        tbody th,
        tbody td.line-title.full {
            font-weight: 700;
            font-size: 12px;
            text-align: center;

            padding: 10px 6px;
            background: $thead2;
        }

        tbody tr {
            &:nth-child(2n) {
                background: $alt;
            }

            &.plus-btn {
                background: rgba($thead, 0.1);
                color: $border;

                transition: all 0.2s ease;
                cursor: pointer;

                &:hover {
                    opacity: 0.8;
                }

                @include icon {
                    font-size: 16px;
                    font-weight: 700;
                    vertical-align: middle;
                }
            }
        }

        tbody td {
            padding: 4px 6px;
            vertical-align: middle;
            // border-right: 1px solid $border;

            &.line-title:not(.full) {
                // font-weight: 700;
                font-size: 14px;
                line-height: 1.2;
                min-width: 200px;
                // background: $linetitle;
            }

            &.delete-btn {
                padding: 2px 4px !important;

                @include icon {
                    transition: all 0.2s ease;
                    cursor: pointer;

                    font-size: 18px;
                    font-weight: 400;
                    text-align: center;

                    color: $border;
                    background: $thead;
                    border-radius: $radius;

                    width: 24px;
                    height: 24px;
                    line-height: 24px;

                    &:hover {
                        opacity: 0.8;
                    }
                }
            }

            input, select,
            .antd-input, .ant-select-selection,
            textarea {
                font-size: 14px;
                border: none;
                border-bottom: 2px solid $lightgrey;

                padding: 6px 8px;
                text-align: center;
            }

            .ant-select-selection {
                min-width: 30px;
                padding: 0;
                // border: none !important;
            }
        }

        input {
            padding-left: 0 !important;
            padding-right: 0 !important;

            &[type="text"] {
                min-width: 180px;
            }

            &[type="number"] {
                min-width: 80px;
            }
        }

        textarea {
            min-width: 200px !important;
        }

        .ant-select {
            min-width: 280px;
        }
    }

    // Checkbox-List
    .checkbox-list {
        .categorie-name th {
            background: $thead;
            font-size: 14px;
            font-weight: 700;

            color: $border;
            background: $thead;

            line-height: 1.2;
            padding: 8px 8px;
            vertical-align: middle;

            @include clearfix;
        }
    }
}

// Styles pour le read-only
.summary-wrap .form-wrap,
.form-wrap.read-only {
    label {
        color: $lightgrey;
    }

    p:not(.section-descr), .ctn span {
        font-weight: 700;
    }

    table td:not(.line-title) {
        font-size: 14px;
        font-weight: 700;
        min-width: 180px;
        text-align: center;
    }

    table td.delete-btn {
        display: none !important;
    }

    .formula {
        input {
            font-weight: 700;
            cursor: default;
            border-color: rgba($lightgrey, 0.24);

            &:hover {
                border-color: rgba($lightgrey, 0.24) !important;
            }
        }
    }

    .checkbox-list {
        td:not(.line-title) {
            min-width: 0 !important;
        }
    }
}
