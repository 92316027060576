.back-button {
    font-size: 14px;
    font-weight: 700;

    color: $mc;

    margin-right: auto;

    display: flex;
    align-items: center;

    position: absolute;
    top: -20px;
    left: 8px;

    @include icon {
        font-size: 14px;
        margin-right: 2px;
    }

    @include mobile {
        margin: 0;
    }
}
