// Notification
.ant-notification-notice {
    // margin-bottom: 0;
    box-shadow: $bsDark;
}

.ant-notification-notice-message {
    font-family: $fontTitle;
    font-size: 14px;
    font-weight: 700;

    color: $grey;

    margin-bottom: 0;
}

.ant-notification-notice-description {
    color: $lightgrey;
}

// Tooltip
.ant-tooltip {
    max-width: 500px;
}

.ant-tooltip-inner {
    font-size: 14px;
    padding: 8px 12px;
}

// Inputs
$h: 40px;

.ant-select {
    width: 100%;
}

.ant-input,
.ant-select-selection {
    font-size: 16px;
    font-weight: 400;

    color: $black !important;
    border: 1px solid $lightgrey;
    border-radius: 0;

    width: 100%;
    height: $h;
    line-height: $h;
}

.ant-select-selection--multiple,
.ant-select-selection__rendered {
    height: auto;
    line-height: $h;
}

.ant-select-selection--multiple > ul > li, .ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
    font-size: 14px;
    // font-weight: 700;
    border-radius: 0;
    border: none;
    color: $black;
    height: $h - 8px;
    line-height: $h - 8px - 2px;

    .ant-select-selection__choice__remove {
        line-height: $h - 8px;
    }
}

.ant-select-dropdown-menu-item-group-list > .ant-select-dropdown-menu-item {
    padding-left: 40px;
}

// Popovers
.ant-popover-content {
    h4 {
        font-size: 12px;
        font-weight: 700;

        color: $lightgrey;
        border-bottom: 1px solid $lightgrey;

        margin-bottom: 4px;
        padding-bottom: 2px;
    }

    ul {
        font-size: 12px;
        padding-left: 15px;
    }

    li {
        list-style-type: disc;
    }
}

// Timeline
.ant-timeline-item-last > .ant-timeline-item-content {
    min-height: auto;
}

// Progress
.button {
    position: relative;

    .ant-progress {
        position: absolute;
        bottom: 0;
    }

    .ant-progress-inner {
        background: none;
        vertical-align: bottom;
    }

    .ant-progress-bg {
        background: darken($mc, 10);
        height: 4px !important;
        border-radius: 0 !important;
        border-bottom-left-radius: $radius !important;
        border-bottom-right-radius: $radius !important;
    }

    &.green .ant-progress-bg {
        background: darken($green, 10);
    }
}

// Popconfirm
.ant-popover {
    max-width: 400px;
}

.ant-popover-buttons {
    display: flex;
    justify-content: flex-end;

    margin-bottom: -20px;
    padding: 10px 0 12px;
    border-top: 1px solid rgba($lightgrey, 0.24);

    button {
        font-size: 12px;
    }
}

.ant-popover-inner-content {
    padding: 20px;
}

.ant-popover-message {
    i {
        display: none;
    }

    .ant-popover-message-title {
        font-size: 14px;
        font-family: $font;
        font-weight: 700;

        padding-left: 0;
    }
}

// Modal
.ant-modal {
    h3 {
        margin-bottom: 10px;
    }

    b {
        color: $mc;
        display: inline-block;
        margin-bottom: 20px;
    }

    .ant-modal-footer > * {
        display: flex;
        justify-content: flex-end;
    }
}

// Switch
.ant-switch {
    &.switch-lg {
        height: 36px;
        &::after {
            height: 32px;
            width: 32px;
        }
        &.ant-switch-checked .ant-switch-inner {
            margin-left: 6px;
            margin-right: 38px;
        }
        .ant-switch-inner {
            font-size: 18px;
            margin-left: 38px;
        }
    }
}
