.vis-crosshair,
.vis-hint {
    background: rgba($black, 0.8);
    box-shadow: $bsDark;
    border-radius: $radius;
    padding: 10px;
    color: $white;

    > * {
        display: block;
        white-space: nowrap;
        line-height: 1.4;
    }

    h3, h4 {
        text-decoration: underline;
        font-size: 10px;
        font-weight: 700;
        color: white;
        margin: 0;
    }

    p, b {
        font-size: 10px;
        margin: 0;
    }
}

.rv-crosshair__line {
    background: lighten($mc, 30);
}

.counter-wrap {
    display: block;
    font-size: 54px;
    font-weight: 700;
    text-align: center;
    line-height: 1.4;
    color: $grey !important;
}

.horiz-histo-wrap {
    margin-top: 20px;

    li {
        width: 100%;
        margin-bottom: 8px;
        line-height: 1.4;

        position: relative;
        height: 30px;

        > span {
            display: flex;
            justify-content: space-between;
        }

        .histo-label {
            position: relative;
            z-index: 2;

            align-items: center;
            height: 100%;
            font-size: 14px;
            // font-family: $fontTitle;

            *:first-child {
                color: $grey;
                font-weight: 500;
                padding-left: 10px;
                color: $black;
                opacity: 0.5;
                font-family: $fontTitle;
                font-weight: 700;
            }

            *:last-child {
                font-size: 16px;
                font-weight: 700;
                width: 50px;
                text-align: left;
                padding-left: 10px;
                white-space: nowrap;
            }
        }

        .histo-bar {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 50px;
            left: 0;

            display: block;
            background: rgba($lightgrey, 0.08);

            &-fill {
                display: block;
                width: 0;
                height: 100%;
                transition: width 0.4s ease;
            }
        }
    }
}

.grouped-horiz-histo-wrap {
    > div {
        position: relative;
        clear: both;

        &:not(:first-child) {
            top: -20px;
        }
    }

    h3 {
        font-size: 20px;
        margin-bottom: 10px;
        margin-top: 20px;
        transition: all 0.2s ease;
        &:first-letter { text-transform: uppercase; }
    }

    .rv-discrete-color-legend {
        float: right;
        width: 280px;
        position: sticky;
        z-index: 10;
        top: 20px;
        right: 20px;
        background: rgba($white, 0.8);
        padding: 14px 10px;
    }

    .horiz-histo-wrap {
        margin-top: 0;
        display: flex;
        flex-flow: row wrap;

        li {
            height: auto;
            width: 50%;

            @include mobile {
                width: 100%;
            }
        }

        .bar-wrap {
            height: 12px;
            position: relative;
        }

        h4 {
            // position: absolute;
            z-index: 2;
            top: 0; bottom: 0;
            // line-height: 30px;

            font-size: 12px;
            font-weight: 500;
            color: $grey;
            font-family: $fontTitle;
            font-weight: 700;
            margin-bottom: 2px;
        }

        .histo-label {
            display: flex;
            justify-content: flex-end;

            *:last-child {
                font-size: 10px;
            }
        }

        .histo-bar {
            .histo-bar-fill {
                min-width: 10px;
            }
        }
    }
}


.pie-chart-wrap {
    position: relative;

    .rv-discrete-color-legend {
        width: 100%;
        position: absolute;
        top: 50%;
        left: 100%;
        transform: translateY(-50%);
    }
}

.grouped-bar-wrap {
    position: relative;
    height: inherit;

    .grouped-bar-legend {
        position: absolute;
        width: auto;
        height: auto;
        top: 0; right: 0;
        background: rgba($white, 0.8);
        padding: 14px 10px;
    }
}


.rv-discrete-color-legend-item {
    padding: 4px 10px;
    cursor: default;

    em {
        display: inline-block;
        opacity: 0.4;
        margin: 0 4px;
    }

    b {
        opacity: 0.8;
    }
}

.rv-xy-plot__axis__tick__text {
    font-size: 10px;
}

.x-axis-label, .y-axis-label {
    font-weight: 700;
    opacity: 0.8;
}

.rv-xy-plot__axis__line,
.rv-xy-plot__axis__ticks line {
    stroke: lighten($lightgrey, 28)
}

.rv-xy-plot__grid-lines__line {
    stroke: lighten($lightgrey, 30)

}

.rv-radial-chart__series--pie__slice,
.horiz-histo-wrap li,
.rv-xy-plot__series--bar rect {
    transition: opacity 0.2s ease;
    cursor: default;

    &.hovered {
        opacity: 1;
    }

    &.not-hovered {
        opacity: 0.1 !important;
    }
}

.rv-xy-plot__series--bar rect[height="0"] {
    height: 0.5px !important;
    transform: translateY(-0.5px);
    // y: 250 !important;
}
