div.togglecolumns {
    //Version adapté du w3school
    //https://www.w3schools.com/howto/howto_css_switch.asp
    /* The switch - the box around the slider */
    .switch {
        position: relative;
        display: inline-block;
        width: 90px;
        height: 34px;
    }

    /* Hide default HTML checkbox */
    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    /* The slider */
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        &::before {
            position: absolute;
        content: '';
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        }
    }

    input:checked + .slider {
        background-color: #2196f3;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px #2196f3;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(56px);
        -ms-transform: translateX(56px);
        transform: translateX(56px);
    }

    .switchlabel {
        position: absolute;
        cursor: pointer;
        top: 22%;
        left: 40%;
        z-index: 1;
    }

    input:checked + .slider  + .switchlabel {
        top: 22%;
        left: 10%;
        color: white;
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }
}
