/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

label {
  font-size: 16px;
  font-weight: 400;
  color: #212121;
  margin-bottom: 6px;
  display: inline-block;
}
label em {
  white-space: nowrap;
}

.required {
  color: #db3739;
  margin-right: 6px;
}

input[type=text],
input[type=password] {
  font-size: 16px;
  font-weight: 400;
  padding: 10px 14px;
  color: #212121;
  background: #ffffff !important;
  border: 1px solid #888888;
  box-sizing: border-box;
  width: 100%;
}
input[type=text]::placeholder,
input[type=password]::placeholder {
  color: rgba(136, 136, 136, 0.5);
}

input[type=checkbox] + label,
input[type=radio] + label {
  font-weight: 700;
  font-size: 14px;
  margin-left: 12px;
}

.detail-box {
  width: 100%;
  margin-top: 4px;
}
.detail-box label {
  font-size: 14px;
  display: inline-block;
}
.detail-box input {
  font-size: 14px;
  height: 80px;
  line-height: 30px;
}

form button, a.button, button.button, .ant-btn {
  appearance: none;
  outline: none !important;
  font-size: 16px;
  font-weight: 700 !important;
  font-family: "Montserrat", sans-serif;
  color: #ffffff;
  background: #db3739;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.2s ease;
  padding: 10px 24px;
  width: auto;
  display: flex;
  align-items: center;
}
form button i, a.button i, button.button i, .ant-btn i {
  margin: 0 6px;
}
form button .material-icons, a.button .material-icons, button.button .material-icons, .ant-btn .material-icons {
  font-size: 14px;
}
form button:hover, a.button:hover, button.button:hover, .ant-btn:hover {
  opacity: 0.8;
  color: #ffffff;
}
form button.alt, a.button.alt, button.button.alt, .ant-btn.alt {
  background: #888888 !important;
}
form button.green, a.button.green, button.button.green, .ant-btn.green {
  background: #a2c754 !important;
}
.ant-btn:not(.ant-btn-primary), form button.ghost, a.button.ghost, button.button.ghost, .ant-btn.ghost {
  background: transparent !important;
  border-color: transparent !important;
  color: #888888;
}
.ant-btn:not(.ant-btn-primary).slim, form button.ghost.slim, a.button.ghost.slim, button.button.ghost.slim, .ant-btn.ghost.slim {
  padding-left: 16px;
  padding-right: 16px;
  font-size: 14px;
}
.ant-btn:not(.ant-btn-primary):hover, form button.ghost:hover, a.button.ghost:hover, button.button.ghost:hover, .ant-btn.ghost:hover {
  opacity: 0.8;
}

form button:disabled, form button.disabled, a.button:disabled, a.button.disabled, button.button:disabled, button.button.disabled, .ant-btn:disabled, .ant-btn.disabled {
  background: #888888 !important;
  opacity: 0.3 !important;
  cursor: not-allowed;
}

.button-link {
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  background: rgba(136, 136, 136, 0.1);
  color: #888888;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.2s ease;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.button-link .material-icons {
  font-size: 16px;
  margin-left: 4px;
}
.button-link:hover {
  color: #888888;
  opacity: 0.8;
}

form p, form div.ctn {
  width: 100%;
  margin-bottom: 16px;
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
}

small.error {
  font-size: 14px;
  font-weight: 700;
  color: #db3739;
  margin-top: 12px;
  display: inline-block;
}

.error-wrap .ant-input,
.error-wrap .ant-select-selection,
.error-wrap input,
.error-input,
.error-input .ant-select-selection {
  border-color: #db3739 !important;
}

body {
  line-height: 1.4;
  background: #f7f7f7;
}

.container {
  max-width: 1200px;
  padding: 0 20px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
@media only screen and (min-width: 1540px) {
  .container {
    max-width: 1340px;
  }
}

.box {
  background: #ffffff;
  border-radius: 20px;
  box-shadow: -8px 6px 20px -10px rgba(219, 55, 57, 0.3);
  padding: 40px 30px;
  margin-bottom: 40px;
  position: relative;
}
.box:empty {
  display: none;
}
.box.table-box {
  padding: 0 0;
}
.box.table-box > h2, .box.table-box > h3 {
  padding: 0 30px;
  margin-top: 40px;
  margin-bottom: 0;
  display: inline-block;
}
.box.title-box {
  padding: 20px 30px;
  margin-bottom: 20px;
}
.box.title-box h2 {
  font-size: 28px;
}
.box.hide {
  display: none;
}

article.infos {
  font-family: "Montserrat", sans-serif;
  margin: 4px 0 20px;
}
article.infos ul {
  display: flex;
  flex-flow: row wrap;
}
article.infos li {
  font-size: 14px;
  line-height: 1.8;
  margin-right: 40px;
  width: auto;
}
article.infos li b {
  font-size: 16px;
}
article.infos li a {
  color: #212121;
  text-decoration: underline;
}

article.buttons {
  background: none !important;
  padding-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}
article.buttons:not(.main-buttons):empty {
  display: none;
}
@media only screen and (max-width: 820px) {
  article.buttons {
    flex-flow: column;
  }
}
article.buttons a {
  box-shadow: -8px 6px 20px -10px #db3739;
  width: calc(50% - 10px);
  margin-bottom: 10px;
  justify-content: center;
}
@media only screen and (max-width: 820px) {
  article.buttons a {
    width: 100%;
  }
}
article.buttons a.full {
  width: 100%;
}
article.buttons a.alt {
  box-shadow: -8px 6px 20px -10px #888888;
}
article.buttons a.green {
  box-shadow: -8px 6px 20px -10px #a2c754;
}
article.buttons.end-button {
  padding-top: 0;
  justify-content: flex-end;
}
article.buttons.end-button:empty {
  margin: 0;
}
article.buttons.end-button a {
  width: auto;
  box-shadow: none;
}

#root {
  position: relative;
  min-height: 100%;
}

body, input, button, textarea {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

h1, h2, h3, .title {
  font-family: "Montserrat", sans-serif;
}

h1 {
  font-size: 60px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 20px;
  color: #555555;
  display: inline-block;
  position: relative;
}
h1:after {
  content: "";
  z-index: -1;
  width: 100%;
  height: 10px;
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  background: repeating-linear-gradient(-45deg, transparent 0px, transparent 6px, #db3739 6px, #db3739 8px);
}
h1:after {
  bottom: 9px;
  left: -20px;
}
@media only screen and (max-width: 820px) {
  h1 {
    font-size: 56px;
  }
}

h2 {
  font-size: 24px;
  font-weight: 700;
  color: #db3739;
}

h3 {
  font-size: 32px;
  font-weight: 700;
  color: #888888;
  margin-bottom: 30px;
}

h2.title-link, h3.title-link {
  transition: all 0.2s ease;
  cursor: pointer;
  font-size: 28px;
  text-decoration: underline;
  display: inline-block;
}
h2.title-link:hover, h3.title-link:hover {
  opacity: 0.8;
}

a {
  color: #db3739;
  text-decoration: none;
  transition: all 0.2s ease;
  cursor: pointer;
}
a:hover, a:active {
  color: #db3739;
  opacity: 0.8;
}

i {
  font-style: normal;
}

b {
  font-weight: 700;
}

.organisations-color .text-fill, .organisations-color.text-fill {
  color: #db3739 !important;
}
.organisations-color .bg-fill, .organisations-color.bg-fill {
  background: #db3739 !important;
}
.organisations-color .bg-opacity-fill, .organisations-color.bg-opacity-fill {
  background: rgba(219, 55, 57, 0.1) !important;
}
.organisations-color .border-fill, .organisations-color.border-fill {
  border-color: #db3739 !important;
}
.organisations-color .border-fill:after, .organisations-color.border-fill:after {
  background: #db3739 !important;
}
.organisations-color h1 {
  position: relative;
}
.organisations-color h1:after {
  content: "";
  z-index: -1;
  width: 100%;
  height: 10px;
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  background: repeating-linear-gradient(-45deg, transparent 0px, transparent 6px, #db3739 6px, #db3739 8px);
}
.organisations-color h1:after {
  bottom: 9px;
  left: -20px;
}
.organisations-color h2 {
  color: #db3739;
}
.organisations-color .box {
  box-shadow: -8px 6px 20px -10px rgba(219, 55, 57, 0.3);
}
.organisations-color form button, .organisations-color .button {
  background: #db3739;
}
.organisations-color .rdt_TableRow:after {
  background: #db3739;
}
.organisations-color .back-button {
  color: #db3739;
}

.requests-color .text-fill, .requests-color.text-fill {
  color: #db3739 !important;
}
.requests-color .bg-fill, .requests-color.bg-fill {
  background: #db3739 !important;
}
.requests-color .bg-opacity-fill, .requests-color.bg-opacity-fill {
  background: rgba(219, 55, 57, 0.1) !important;
}
.requests-color .border-fill, .requests-color.border-fill {
  border-color: #db3739 !important;
}
.requests-color .border-fill:after, .requests-color.border-fill:after {
  background: #db3739 !important;
}
.requests-color h1 {
  position: relative;
}
.requests-color h1:after {
  content: "";
  z-index: -1;
  width: 100%;
  height: 10px;
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  background: repeating-linear-gradient(-45deg, transparent 0px, transparent 6px, #db3739 6px, #db3739 8px);
}
.requests-color h1:after {
  bottom: 9px;
  left: -20px;
}
.requests-color h2 {
  color: #db3739;
}
.requests-color .box {
  box-shadow: -8px 6px 20px -10px rgba(219, 55, 57, 0.3);
}
.requests-color form button, .requests-color .button {
  background: #db3739;
}
.requests-color .rdt_TableRow:after {
  background: #db3739;
}
.requests-color .back-button {
  color: #db3739;
}

.users-color .text-fill, .users-color.text-fill {
  color: #db3739 !important;
}
.users-color .bg-fill, .users-color.bg-fill {
  background: #db3739 !important;
}
.users-color .bg-opacity-fill, .users-color.bg-opacity-fill {
  background: rgba(219, 55, 57, 0.1) !important;
}
.users-color .border-fill, .users-color.border-fill {
  border-color: #db3739 !important;
}
.users-color .border-fill:after, .users-color.border-fill:after {
  background: #db3739 !important;
}
.users-color h1 {
  position: relative;
}
.users-color h1:after {
  content: "";
  z-index: -1;
  width: 100%;
  height: 10px;
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  background: repeating-linear-gradient(-45deg, transparent 0px, transparent 6px, #db3739 6px, #db3739 8px);
}
.users-color h1:after {
  bottom: 9px;
  left: -20px;
}
.users-color h2 {
  color: #db3739;
}
.users-color .box {
  box-shadow: -8px 6px 20px -10px rgba(219, 55, 57, 0.3);
}
.users-color form button, .users-color .button {
  background: #db3739;
}
.users-color .rdt_TableRow:after {
  background: #db3739;
}
.users-color .back-button {
  color: #db3739;
}

.activity-color .text-fill, .activity-color.text-fill {
  color: #db3739 !important;
}
.activity-color .bg-fill, .activity-color.bg-fill {
  background: #db3739 !important;
}
.activity-color .bg-opacity-fill, .activity-color.bg-opacity-fill {
  background: rgba(219, 55, 57, 0.1) !important;
}
.activity-color .border-fill, .activity-color.border-fill {
  border-color: #db3739 !important;
}
.activity-color .border-fill:after, .activity-color.border-fill:after {
  background: #db3739 !important;
}
.activity-color h1 {
  position: relative;
}
.activity-color h1:after {
  content: "";
  z-index: -1;
  width: 100%;
  height: 10px;
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  background: repeating-linear-gradient(-45deg, transparent 0px, transparent 6px, #db3739 6px, #db3739 8px);
}
.activity-color h1:after {
  bottom: 9px;
  left: -20px;
}
.activity-color h2 {
  color: #db3739;
}
.activity-color .box {
  box-shadow: -8px 6px 20px -10px rgba(219, 55, 57, 0.3);
}
.activity-color form button, .activity-color .button {
  background: #db3739;
}
.activity-color .rdt_TableRow:after {
  background: #db3739;
}
.activity-color .back-button {
  color: #db3739;
}

.ant-notification-notice {
  box-shadow: 0 0 30px -2px rgba(33, 33, 33, 0.2);
}

.ant-notification-notice-message {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: #555555;
  margin-bottom: 0;
}

.ant-notification-notice-description {
  color: #888888;
}

.ant-tooltip {
  max-width: 500px;
}

.ant-tooltip-inner {
  font-size: 14px;
  padding: 8px 12px;
}

.ant-select {
  width: 100%;
}

.ant-input,
.ant-select-selection {
  font-size: 16px;
  font-weight: 400;
  color: #212121 !important;
  border: 1px solid #888888;
  border-radius: 0;
  width: 100%;
  height: 40px;
  line-height: 40px;
}

.ant-select-selection--multiple,
.ant-select-selection__rendered {
  height: auto;
  line-height: 40px;
}

.ant-select-selection--multiple > ul > li, .ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
  font-size: 14px;
  border-radius: 0;
  border: none;
  color: #212121;
  height: 32px;
  line-height: 30px;
}
.ant-select-selection--multiple > ul > li .ant-select-selection__choice__remove, .ant-select-selection--multiple .ant-select-selection__rendered > ul > li .ant-select-selection__choice__remove {
  line-height: 32px;
}

.ant-select-dropdown-menu-item-group-list > .ant-select-dropdown-menu-item {
  padding-left: 40px;
}

.ant-popover-content h4 {
  font-size: 12px;
  font-weight: 700;
  color: #888888;
  border-bottom: 1px solid #888888;
  margin-bottom: 4px;
  padding-bottom: 2px;
}
.ant-popover-content ul {
  font-size: 12px;
  padding-left: 15px;
}
.ant-popover-content li {
  list-style-type: disc;
}

.ant-timeline-item-last > .ant-timeline-item-content {
  min-height: auto;
}

.button {
  position: relative;
}
.button .ant-progress {
  position: absolute;
  bottom: 0;
}
.button .ant-progress-inner {
  background: none;
  vertical-align: bottom;
}
.button .ant-progress-bg {
  background: #bd2224;
  height: 4px !important;
  border-radius: 0 !important;
  border-bottom-left-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
}
.button.green .ant-progress-bg {
  background: #89af39;
}

.ant-popover {
  max-width: 400px;
}

.ant-popover-buttons {
  display: flex;
  justify-content: flex-end;
  margin-bottom: -20px;
  padding: 10px 0 12px;
  border-top: 1px solid rgba(136, 136, 136, 0.24);
}
.ant-popover-buttons button {
  font-size: 12px;
}

.ant-popover-inner-content {
  padding: 20px;
}

.ant-popover-message i {
  display: none;
}
.ant-popover-message .ant-popover-message-title {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  padding-left: 0;
}

.ant-modal h3 {
  margin-bottom: 10px;
}
.ant-modal b {
  color: #db3739;
  display: inline-block;
  margin-bottom: 20px;
}
.ant-modal .ant-modal-footer > * {
  display: flex;
  justify-content: flex-end;
}

.ant-switch.switch-lg {
  height: 36px;
}
.ant-switch.switch-lg::after {
  height: 32px;
  width: 32px;
}
.ant-switch.switch-lg.ant-switch-checked .ant-switch-inner {
  margin-left: 6px;
  margin-right: 38px;
}
.ant-switch.switch-lg .ant-switch-inner {
  font-size: 18px;
  margin-left: 38px;
}

@media print {
  body {
    background: none !important;
  }
  .container {
    max-width: none !important;
  }
  .general-header {
    display: none !important;
  }
  .form-box {
    margin-left: 0 !important;
    width: 100% !important;
    box-shadow: none !important;
    padding: 0 !important;
  }
  .button, .buttons-container {
    display: none !important;
  }
  footer {
    display: none !important;
  }
  .dashboard-main-nav, .stats-filters {
    display: none !important;
  }
  .stats-page h1 {
    margin-top: 20px !important;
  }
  .stats-page h1 .material-icons {
    display: none !important;
  }
  .stats-graphs section {
    margin-top: 0 !important;
    page-break-inside: avoid;
  }
  .rv-xy-plot__grid-lines {
    opacity: 0.1;
  }
  .horiz-histo-section-wrap {
    page-break-inside: avoid;
  }
  .grouped-horiz-histo-wrap > div {
    top: 0 !important;
  }
}
.general-header {
  height: 80px;
  margin-top: 10px;
  margin-bottom: 60px;
  position: relative;
  z-index: 999;
}
@media only screen and (max-width: 820px) {
  .general-header {
    height: auto;
  }
}
.general-header nav {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}
@media only screen and (max-width: 820px) {
  .general-header nav {
    flex-flow: column;
  }
}
.general-header a {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  padding: 0 12px;
  display: inline-block;
}
.general-header .home-link {
  float: left;
  margin-right: 24px;
  padding-left: 0;
  height: 80px;
}
.general-header .home-link img {
  height: 80px;
  padding: 8px 0;
}
.general-header .primary-links {
  height: 80px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
@media only screen and (max-width: 820px) {
  .general-header .primary-links {
    margin-top: 20px;
  }
}
.general-header .primary-links > a {
  color: #888888;
  padding: 0 16px;
  display: flex;
  align-items: center;
  position: relative;
}
.general-header .primary-links > a .material-icons {
  font-size: 16px;
  margin-right: 8px;
}
@media only screen and (max-width: 820px) {
  .general-header .primary-links > a {
    height: 30px;
    line-height: 30px;
    padding: 0;
    margin-right: 20px;
    margin-bottom: 10px;
  }
}
.general-header .primary-links > a:after {
  content: "";
  background: #888888;
  transition: all 0.2s ease;
  width: 0;
  height: 3px;
  display: block;
  position: absolute;
  left: 46px;
  bottom: 0;
}
@media only screen and (max-width: 820px) {
  .general-header .primary-links > a:after {
    left: 26px;
    bottom: -6px;
  }
}
.general-header .primary-links > a:hover:after {
  width: 20px;
}
.general-header .primary-links > a.active, .general-header .primary-links > a:hover.active {
  opacity: 1;
}
.general-header .primary-links > a.active:after, .general-header .primary-links > a:hover.active:after {
  width: 20px;
  background: #db3739;
}
@media only screen and (min-width: 820px) {
  .general-header .primary-links > a.has-subroutes:hover {
    opacity: 1;
  }
  .general-header .primary-links > a.has-subroutes:hover .subroutes {
    display: flex;
  }
  .general-header .primary-links > a.has-subroutes:hover:after {
    width: 100%;
    left: 0;
  }
  .general-header .primary-links > a.has-subroutes .subroutes {
    background: #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 30px -2px rgba(33, 33, 33, 0.1);
    padding-top: 76px;
    padding-bottom: 12px;
    display: none;
    flex-flow: column nowrap;
    position: absolute;
    left: 0;
    right: 0;
    top: 8px;
    z-index: -1;
  }
  .general-header .primary-links > a.has-subroutes .subroutes a {
    transition: all 0.2s ease;
    font-size: 12px;
    color: #888888;
    height: auto;
    line-height: 1.4;
    padding: 8px 12px 8px 20px;
  }
  .general-header .primary-links > a.has-subroutes .subroutes a:hover {
    opacity: 0.8;
  }
}
.general-header .secondary-links {
  margin-left: auto;
  height: 80px;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 820px) {
  .general-header .secondary-links {
    position: absolute;
    top: 0;
    right: 20px;
  }
}
.general-header .secondary-links .user-link {
  font-size: 12px;
  height: auto;
  line-height: 1.4;
}
.general-header .secondary-links .user-link small {
  font-size: 10px;
  font-weight: 400;
  color: #888888;
  margin-top: 4px;
  line-height: 1;
  display: block;
}
.general-header .secondary-links .logout-button {
  color: #555555;
  padding-right: 0;
}
.general-header .secondary-links .logout-button .material-icons {
  font-size: 18px;
  font-weight: 700;
}

.general-footer {
  margin-top: 80px;
}
.general-footer .container {
  border-top: 1px solid #888888;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
}
.general-footer small {
  font-size: 12px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  color: #888888;
}
.general-footer small .separator {
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  display: inline-block;
  margin: 0 12px;
}

.back-button {
  font-size: 14px;
  font-weight: 700;
  color: #db3739;
  margin-right: auto;
  display: flex;
  align-items: center;
  position: absolute;
  top: -20px;
  left: 8px;
}
.back-button .material-icons {
  font-size: 14px;
  margin-right: 2px;
}
@media only screen and (max-width: 820px) {
  .back-button {
    margin: 0;
  }
}

#root .reload-filters-btn {
  cursor: pointer;
  transition: all 0.2s ease;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 900;
  color: #db3739;
  display: flex;
  align-items: center;
  position: absolute;
  top: 25px;
  left: 30px;
  z-index: 25;
}
#root .reload-filters-btn .material-icons {
  margin-right: 10px;
  font-size: 16px;
}
#root .reload-filters-btn i {
  text-decoration: underline;
}
#root .reload-filters-btn:hover {
  opacity: 0.8;
}
@media only screen and (max-width: 820px) {
  #root .reload-filters-btn {
    padding: 30px 20px 0;
    position: static;
    text-align: right;
    display: block;
  }
}
#root h3 + .reload-filters-btn {
  position: static;
  float: right;
  padding: 0 30px;
  margin-top: 45px;
}
@media only screen and (max-width: 820px) {
  #root h3 + .reload-filters-btn {
    margin-top: 0;
    padding: 20px;
    float: none;
  }
}
#root .MuiPaper-elevation2 {
  box-shadow: none;
  background: none;
  font-family: "Roboto", sans-serif;
}
#root .MuiToolbar-root .material-icons {
  font-size: 22px;
}
#root .MuiToolbar-root .material-icons.main {
  font-size: 28px;
  color: #db3739;
}
.MuiFormControlLabel-root {
  pointer-events: none;
}

.MuiPopover-paper {
  width: auto !important;
}

#root .MuiTableHead-root .MuiTableSortLabel-icon {
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  width: 18px;
  height: 18px;
  line-height: 18px;
}
#root .MuiTableHead-root .MuiTableCell-root {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 12px;
  color: #555555;
  padding-bottom: 8px;
  padding-top: 6px;
  vertical-align: bottom;
}
#root .MuiTableHead-root .MuiTableCell-root:before {
  font-size: 8px;
  padding-right: 8px;
  margin-left: -16px;
}
#root .MuiTableBody-root .MuiTableRow-root {
  transition: all 0.2s ease;
  cursor: pointer;
  position: relative;
}
#root .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root {
  font-size: 14px !important;
}
#root .MuiTableBody-root .MuiTableRow-root .material-icons {
  color: #888888;
}
#root .MuiTableBody-root .MuiTableRow-root:nth-child(2n+3) {
  background: rgba(136, 136, 136, 0.05);
}
#root .MuiTableBody-root .MuiTableRow-root:not(:first-child):hover {
  background: rgba(136, 136, 136, 0.2);
}
#root .MuiTableBody-root .MuiTableRow-root:first-child {
  cursor: default;
  background: rgba(219, 55, 57, 0.2);
}
#root .MuiTableBody-root .MuiTableRow-root:first-child .MuiTableCell-root {
  padding-top: 8px;
  padding-bottom: 8px;
}
#root .MuiTableBody-root .MuiTableRow-root:first-child .MuiInput-root {
  border: 1px solid #888888;
  background: #ffffff;
  height: 30px;
}
#root .MuiTableBody-root .MuiTableRow-root:first-child .MuiInput-root:before, #root .MuiTableBody-root .MuiTableRow-root:first-child .MuiInput-root:after {
  display: none !important;
}
#root .MuiTableBody-root .MuiTableRow-root:first-child .MuiInput-root .material-icons {
  font-size: 14px;
  font-weight: 700;
  margin-left: 4px;
}
#root .MuiTableBody-root .MuiTableRow-root:first-child label + .MuiInput-formControl {
  margin-top: 0;
}
#root .MuiTableBody-root .MuiTableRow-root:first-child input,
#root .MuiTableBody-root .MuiTableRow-root:first-child .MuiSelect-selectMenu {
  font-size: 12px;
  font-weight: 700;
  border: none;
  padding: 0;
}
#root .MuiTableBody-root .MuiTableRow-root:first-child .MuiSelect-selectMenu {
  padding-right: 25px;
  padding-left: 6px;
}
#root .MuiTableBody-root .MuiTableRow-root:empty {
  display: none;
}
#root .MuiTableFooter-root .MuiTableCell-root {
  border: none;
}

.form-infos {
  margin-top: 20px;
  float: right;
  text-align: right;
}
.form-infos > * {
  font-family: "Montserrat", sans-serif;
  color: #555555;
  display: block;
}
.form-infos .date {
  font-size: 16px;
}
.form-infos .status {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 10px;
}
@media only screen and (max-width: 820px) {
  .form-infos {
    margin-top: 0;
    text-align: left;
    float: none;
  }
  .form-infos * {
    display: inline-block;
  }
  .form-infos .date {
    margin-right: 20px;
  }
}

.form-infos + .infos {
  padding-right: 80px;
}

.form-progress-wrap {
  position: relative;
}
.form-progress-wrap .percentage {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  color: #db3739;
  position: absolute;
  top: -48px;
  right: 0;
}
@media only screen and (max-width: 820px) {
  .form-progress-wrap .percentage {
    position: static;
    margin-top: 10px;
    width: 100px;
    text-align: right;
  }
}
@media only screen and (max-width: 820px) {
  .form-progress-wrap {
    display: flex;
    flex-flow: row-reverse;
  }
}

.form-progress {
  background: #ffffff;
  border-radius: 3px;
  box-shadow: 0 0 30px -2px rgba(33, 33, 33, 0.1);
  margin-top: 20px;
  margin-bottom: 40px;
  width: 100%;
  height: 8px;
  position: relative;
}
.form-progress .fill {
  transition: all 0.2s ease;
  background: #db3739;
  border: 4px solid #e77879;
  border-radius: 3px;
  min-width: 15px;
  height: 200%;
  position: absolute;
  top: -4px;
}

.form-wrap {
  display: flex;
  justify-content: flex-start;
}
.form-wrap::after {
  content: "";
  clear: both;
  display: table;
}
.form-wrap form {
  width: 100%;
}
.form-wrap form::after {
  content: "";
  clear: both;
  display: table;
}
.form-wrap form > * {
  float: left;
}

.form-nav {
  margin-top: 60px;
  width: 300px;
  position: sticky;
  top: 60px;
}
@media only screen and (max-width: 820px) {
  .form-nav {
    margin-top: 20px;
    width: 100%;
    position: relative;
    top: 0;
  }
}
.form-nav .print-btn {
  margin-top: 60px;
  display: inline-block;
}
@media only screen and (max-width: 820px) {
  .form-nav .print-btn {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 820px) {
  .form-nav > button, .form-nav .print-btn {
    float: right;
    clear: both;
  }
}
.form-nav > button.save, .form-nav .print-btn.save {
  margin-top: 10px;
}
.form-nav small {
  color: #888888;
  margin-top: 20px;
  display: flex;
}
@media only screen and (max-width: 820px) {
  .form-nav small {
    margin-top: 20px;
    clear: both;
    float: right;
    flex-flow: row-reverse;
  }
}
.form-nav small .material-icons {
  text-align: center;
  width: 36px;
  height: 36px;
  line-height: 36px;
}
.form-nav small i {
  font-size: 14px;
  font-weight: 400;
  margin-left: 12px;
  display: inline-block;
}
@media only screen and (max-width: 820px) {
  .form-nav small i {
    text-align: right;
    margin-right: 12px;
    margin-left: 0;
  }
}
.form-nav small time {
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  display: block;
}
.form-nav small time::first-letter {
  text-transform: uppercase;
}
.form-nav li {
  font-size: 14px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  color: #555555;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}
.form-nav li button {
  text-align: left;
  color: #555555;
  background: none;
  padding: 0;
}
.form-nav li button:focus {
  outline: none;
}
.form-nav li i {
  margin-left: 12px;
}
.form-nav li .material-icons {
  font-size: 18px;
  text-align: center;
  color: #ffffff;
  background: #555555;
  border: 4px solid #bbbbbb;
  border-radius: 50%;
  line-height: 28px;
  width: 36px;
  height: 36px;
}
.form-nav li:hover {
  opacity: 0.8;
}
.form-nav li.active button {
  color: #db3739 !important;
}
.form-nav li.active .material-icons {
  background: #db3739 !important;
  border-color: #e77879 !important;
}
.form-nav li.active:hover {
  opacity: 1;
}
.form-nav li.done .material-icons {
  background: #a2c754;
  border-color: #c1da8e;
}
.form-nav li.error .material-icons {
  background: #db3739;
  border-color: #e77879;
}

.form-box {
  margin-left: 20px;
  width: calc(100% - 300px - 20px);
}
.form-box::after {
  content: "";
  clear: both;
  display: table;
}
@media only screen and (max-width: 820px) {
  .form-box {
    margin-left: 0;
    margin-top: 40px;
    width: 100%;
  }
}
.form-box h2 {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
}
.form-box h2 .material-icons {
  font-size: 24px;
  font-weight: 700;
  margin-right: 12px;
}
.form-box h3 {
  font-size: 20px;
  margin-top: 40px;
  margin-bottom: 20px;
}
.form-box h3.important {
  font-size: 14px;
  color: #db3739;
}
.form-box .section-descr,
.form-box .financial-link {
  font-size: 14px;
  color: #555555;
  margin-bottom: 30px;
}
.form-box .section-descr a,
.form-box .financial-link a {
  margin-top: 8px;
  font-weight: 700;
}
.form-box .section-descr + h3 {
  margin-top: 0;
}
.form-box button {
  float: right;
}
.form-box label .material-icons {
  font-size: 16px;
  transition: all 0.2s ease;
  color: #888888;
  cursor: help;
  margin-left: 8px;
  transform: translateY(3px);
}
.form-box label .material-icons:hover {
  opacity: 0.8;
}
.form-box section:not([data-section-id="1"]) p:not(.section-descr),
.form-box section:not([data-section-id="1"]) .ctn {
  margin-bottom: 28px;
}
.form-box small.char-count {
  font-size: 12px;
  color: #888888;
  margin-top: 4px;
}
.form-box .radio-wrap,
.form-box .checkbox-wrap {
  margin-bottom: 6px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.form-box .radio-wrap label,
.form-box .checkbox-wrap label {
  margin-bottom: 0;
}
.form-box .formula label {
  margin-bottom: 12px;
}
.form-box .formula input {
  text-align: center;
}
.form-box .formula .help-formula,
.form-box .formula .upper-formula,
.form-box .formula .lower-formula {
  width: 100%;
  display: flex;
  align-items: center;
}
.form-box .formula .help-formula i,
.form-box .formula .upper-formula i,
.form-box .formula .lower-formula i {
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  display: inline-block;
  width: 20px;
}
.form-box .formula .help-formula {
  font-size: 12px;
  font-weight: 700;
  color: #555555;
  text-align: center;
}
.form-box .formula .help-formula span {
  display: inline-block;
}
.form-box .formula .help-formula span:nth-of-type(1) {
  width: calc(25% - 20px);
}
.form-box .formula .help-formula span:nth-of-type(2),
.form-box .formula .help-formula span:nth-of-type(3) {
  width: calc(25% - 10px);
  color: #888888;
}
.form-box .formula .help-formula span:nth-of-type(4) {
  width: calc(25% - 20px - 20px);
}
.form-box .formula .upper-formula {
  justify-content: center;
  margin-bottom: 6px;
}
.form-box .formula .upper-formula input {
  width: calc(25% - 10px);
}
.form-box .formula .lower-formula input:nth-of-type(1) {
  width: calc(25% - 20px);
}
.form-box .formula .lower-formula input:nth-of-type(2) {
  width: 50%;
}
.form-box .formula .lower-formula input:nth-of-type(3) {
  width: calc(25% - 20px - 20px);
}
.form-box .table-wrap {
  position: relative;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  display: block;
  overflow-x: scroll;
}
.form-box .file-type > span {
  font-size: 14px;
  color: #555555;
  margin-left: 24px;
  margin-top: 8px;
}
.form-box .file-type > span b, .form-box .file-type > span input {
  font-size: 16px;
  color: #212121;
  margin-left: 8px;
  display: inline-block;
}
.form-box .file-type > small {
  margin-left: 24px;
  margin-top: 8px;
}
.form-box table {
  min-width: 100%;
  margin-top: 6px;
  border: 2px solid #671213;
}
.form-box table thead {
  font-size: 14px;
  font-weight: 700;
  color: #671213;
  background: rgba(219, 55, 57, 0.4);
  border: 2px solid #671213;
  line-height: 1.2;
}
.form-box table thead th {
  padding: 8px 8px;
  vertical-align: middle;
}
.form-box table th::first-letter {
  text-transform: uppercase;
}
.form-box table tbody th,
.form-box table tbody td.line-title.full {
  font-weight: 700;
  font-size: 12px;
  text-align: center;
  padding: 10px 6px;
  background: #f2b9b9;
}
.form-box table tbody tr:nth-child(2n) {
  background: rgba(136, 136, 136, 0.1);
}
.form-box table tbody tr.plus-btn {
  background: rgba(219, 55, 57, 0.1);
  color: #671213;
  transition: all 0.2s ease;
  cursor: pointer;
}
.form-box table tbody tr.plus-btn:hover {
  opacity: 0.8;
}
.form-box table tbody tr.plus-btn .material-icons {
  font-size: 16px;
  font-weight: 700;
  vertical-align: middle;
}
.form-box table tbody td {
  padding: 4px 6px;
  vertical-align: middle;
}
.form-box table tbody td.line-title:not(.full) {
  font-size: 14px;
  line-height: 1.2;
  min-width: 200px;
}
.form-box table tbody td.delete-btn {
  padding: 2px 4px !important;
}
.form-box table tbody td.delete-btn .material-icons {
  transition: all 0.2s ease;
  cursor: pointer;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  color: #671213;
  background: rgba(219, 55, 57, 0.4);
  border-radius: 3px;
  width: 24px;
  height: 24px;
  line-height: 24px;
}
.form-box table tbody td.delete-btn .material-icons:hover {
  opacity: 0.8;
}
.form-box table tbody td input, .form-box table tbody td select,
.form-box table tbody td .antd-input, .form-box table tbody td .ant-select-selection,
.form-box table tbody td textarea {
  font-size: 14px;
  border: none;
  border-bottom: 2px solid #888888;
  padding: 6px 8px;
  text-align: center;
}
.form-box table tbody td .ant-select-selection {
  min-width: 30px;
  padding: 0;
}
.form-box table input {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.form-box table input[type=text] {
  min-width: 180px;
}
.form-box table input[type=number] {
  min-width: 80px;
}
.form-box table textarea {
  min-width: 200px !important;
}
.form-box table .ant-select {
  min-width: 280px;
}
.form-box .checkbox-list .categorie-name th {
  background: rgba(219, 55, 57, 0.4);
  font-size: 14px;
  font-weight: 700;
  color: #671213;
  background: rgba(219, 55, 57, 0.4);
  line-height: 1.2;
  padding: 8px 8px;
  vertical-align: middle;
}
.form-box .checkbox-list .categorie-name th::after {
  content: "";
  clear: both;
  display: table;
}

.summary-wrap .form-wrap label,
.form-wrap.read-only label {
  color: #888888;
}
.summary-wrap .form-wrap p:not(.section-descr), .summary-wrap .form-wrap .ctn span,
.form-wrap.read-only p:not(.section-descr),
.form-wrap.read-only .ctn span {
  font-weight: 700;
}
.summary-wrap .form-wrap table td:not(.line-title),
.form-wrap.read-only table td:not(.line-title) {
  font-size: 14px;
  font-weight: 700;
  min-width: 180px;
  text-align: center;
}
.summary-wrap .form-wrap table td.delete-btn,
.form-wrap.read-only table td.delete-btn {
  display: none !important;
}
.summary-wrap .form-wrap .formula input,
.form-wrap.read-only .formula input {
  font-weight: 700;
  cursor: default;
  border-color: rgba(136, 136, 136, 0.24);
}
.summary-wrap .form-wrap .formula input:hover,
.form-wrap.read-only .formula input:hover {
  border-color: rgba(136, 136, 136, 0.24) !important;
}
.summary-wrap .form-wrap .checkbox-list td:not(.line-title),
.form-wrap.read-only .checkbox-list td:not(.line-title) {
  min-width: 0 !important;
}

.popup-box {
  background: #ffffff;
  border: 1px solid rgb(215, 215, 215);
  box-shadow: 0 0 30px -2px rgba(33, 33, 33, 0.1);
  padding: 10px;
  width: 250px;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  top: 50px;
  right: 0;
  z-index: 98;
}
@media only screen and (max-width: 820px) {
  .popup-box {
    padding: 20px !important;
  }
}
.popup-box.show {
  display: flex;
}
.popup-box.hide {
  display: none;
}
.popup-box:before {
  content: "";
  display: block;
  position: absolute;
  top: -22px;
  right: 20px;
  background: #db3739;
  width: 2px;
  height: 22px;
}
@media only screen and (max-width: 820px) {
  .popup-box {
    width: calc(100% + 20px + 20px) !important;
    right: -20px;
  }
  .popup-box:before {
    right: 40px;
  }
}

.first-level-options-table,
.second-level-options-table {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
@media only screen and (max-width: 820px) {
  .first-level-options-table,
  .second-level-options-table {
    flex-flow: column nowrap;
    justify-content: flex-end;
    align-items: flex-end;
  }
}
.first-level-options-table a,
.second-level-options-table a {
  height: 30px;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  align-items: center;
}
.first-level-options-table a i,
.second-level-options-table a i {
  margin: 0 6px;
}
.first-level-options-table a .material-icons,
.second-level-options-table a .material-icons {
  font-size: 12px;
}

.first-level-options-table {
  margin-bottom: 20px;
}
@media only screen and (max-width: 820px) {
  .first-level-options-table {
    margin-top: 20px;
    margin-bottom: 8px;
    flex-flow: column-reverse;
  }
}

.second-level-options-table {
  margin-bottom: 8px;
}

.first-level-options-table .table-buttons {
  display: none !important;
  font-size: 12px;
  margin-left: 16px;
  display: flex;
}
@media only screen and (max-width: 820px) {
  .first-level-options-table .table-buttons {
    width: 100%;
    margin-left: 0;
  }
  .first-level-options-table .table-buttons .material-icons {
    display: none;
  }
}
.first-level-options-table .table-buttons a {
  font-size: 12px;
}
.first-level-options-table .table-buttons a:last-of-type {
  border-left: none;
}
@media only screen and (max-width: 820px) {
  .first-level-options-table .table-buttons a {
    width: 50%;
    justify-content: center;
  }
  .first-level-options-table .table-buttons a i {
    margin: 0;
  }
}

.filters-block {
  position: relative;
}
@media only screen and (max-width: 820px) {
  .filters-block {
    width: 100%;
    margin-top: 8px;
  }
}
.filters-block a {
  font-size: 12px;
  margin-left: 28px;
}
@media only screen and (max-width: 820px) {
  .filters-block a {
    width: 100%;
    margin: 0;
    justify-content: center;
  }
}
.filters-block form {
  background: #ffffff;
  border: 1px solid rgb(215, 215, 215);
  box-shadow: 0 0 30px -2px rgba(33, 33, 33, 0.1);
  padding: 10px 20px 10px 10px;
  width: 360px;
  flex-flow: column nowrap;
  align-items: flex-start;
  position: absolute;
}
.filters-block form label {
  font-size: 12px;
  width: 90px;
}
.filters-block form input {
  font-size: 12px;
  padding: 2px 4px;
  width: 234px;
}
@media only screen and (max-width: 820px) {
  .filters-block form input {
    width: 100%;
  }
}
.filters-block form .ant-select {
  font-size: 12px;
  font-weight: 700;
  width: 234px;
}
@media only screen and (max-width: 820px) {
  .filters-block form .ant-select {
    width: 100%;
  }
}
.filters-block form .ant-select .ant-select-selection {
  border-radius: 0;
  border: none;
  border-bottom: 2px solid #212121;
  outline: none;
  box-shadow: none;
}
.filters-block form .ant-select.ant-select-focused .ant-select-selection {
  border-color: #db3739;
}
.filters-block form .ant-select .ant-select-selection__choice {
  font-size: 12px;
  color: #888888;
  background: rgba(136, 136, 136, 0.24);
  border: none;
  border-radius: 0;
  height: 22px;
  line-height: 22px;
}
.filters-block form .form-buttons {
  margin-top: 16px;
  display: flex;
  align-self: flex-end;
}
.filters-block form button, .filters-block form .button {
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
}
.filters-block form button .material-icons, .filters-block form .button .material-icons {
  font-size: 12px;
}

.ant-select-dropdown-menu-item {
  font-size: 12px;
}

.ant-select-search__field {
  padding: 0 !important;
  font-weight: 700;
}

.columns-display {
  position: relative;
}
@media only screen and (max-width: 820px) {
  .columns-display {
    width: 100%;
  }
}
.columns-display a {
  font-size: 12px;
}
@media only screen and (max-width: 820px) {
  .columns-display a {
    width: 100%;
    justify-content: center;
  }
}
.columns-display ul li {
  width: 110px;
  font-size: 12px;
}
@media only screen and (max-width: 820px) {
  .columns-display ul li {
    width: 50%;
  }
}
.columns-display ul li label {
  font-size: 12px;
  font-weight: 500;
  margin-left: 8px;
  margin-right: 0;
}

.vis-crosshair,
.vis-hint {
  background: rgba(33, 33, 33, 0.8);
  box-shadow: 0 0 30px -2px rgba(33, 33, 33, 0.2);
  border-radius: 3px;
  padding: 10px;
  color: #ffffff;
}
.vis-crosshair > *,
.vis-hint > * {
  display: block;
  white-space: nowrap;
  line-height: 1.4;
}
.vis-crosshair h3, .vis-crosshair h4,
.vis-hint h3,
.vis-hint h4 {
  text-decoration: underline;
  font-size: 10px;
  font-weight: 700;
  color: white;
  margin: 0;
}
.vis-crosshair p, .vis-crosshair b,
.vis-hint p,
.vis-hint b {
  font-size: 10px;
  margin: 0;
}

.rv-crosshair__line {
  background: #f2b9b9;
}

.counter-wrap {
  display: block;
  font-size: 54px;
  font-weight: 700;
  text-align: center;
  line-height: 1.4;
  color: #555555 !important;
}

.horiz-histo-wrap {
  margin-top: 20px;
}
.horiz-histo-wrap li {
  width: 100%;
  margin-bottom: 8px;
  line-height: 1.4;
  position: relative;
  height: 30px;
}
.horiz-histo-wrap li > span {
  display: flex;
  justify-content: space-between;
}
.horiz-histo-wrap li .histo-label {
  position: relative;
  z-index: 2;
  align-items: center;
  height: 100%;
  font-size: 14px;
}
.horiz-histo-wrap li .histo-label *:first-child {
  color: #555555;
  font-weight: 500;
  padding-left: 10px;
  color: #212121;
  opacity: 0.5;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}
.horiz-histo-wrap li .histo-label *:last-child {
  font-size: 16px;
  font-weight: 700;
  width: 50px;
  text-align: left;
  padding-left: 10px;
  white-space: nowrap;
}
.horiz-histo-wrap li .histo-bar {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 50px;
  left: 0;
  display: block;
  background: rgba(136, 136, 136, 0.08);
}
.horiz-histo-wrap li .histo-bar-fill {
  display: block;
  width: 0;
  height: 100%;
  transition: width 0.4s ease;
}

.grouped-horiz-histo-wrap > div {
  position: relative;
  clear: both;
}
.grouped-horiz-histo-wrap > div:not(:first-child) {
  top: -20px;
}
.grouped-horiz-histo-wrap h3 {
  font-size: 20px;
  margin-bottom: 10px;
  margin-top: 20px;
  transition: all 0.2s ease;
}
.grouped-horiz-histo-wrap h3:first-letter {
  text-transform: uppercase;
}
.grouped-horiz-histo-wrap .rv-discrete-color-legend {
  float: right;
  width: 280px;
  position: sticky;
  z-index: 10;
  top: 20px;
  right: 20px;
  background: rgba(255, 255, 255, 0.8);
  padding: 14px 10px;
}
.grouped-horiz-histo-wrap .horiz-histo-wrap {
  margin-top: 0;
  display: flex;
  flex-flow: row wrap;
}
.grouped-horiz-histo-wrap .horiz-histo-wrap li {
  height: auto;
  width: 50%;
}
@media only screen and (max-width: 820px) {
  .grouped-horiz-histo-wrap .horiz-histo-wrap li {
    width: 100%;
  }
}
.grouped-horiz-histo-wrap .horiz-histo-wrap .bar-wrap {
  height: 12px;
  position: relative;
}
.grouped-horiz-histo-wrap .horiz-histo-wrap h4 {
  z-index: 2;
  top: 0;
  bottom: 0;
  font-size: 12px;
  font-weight: 500;
  color: #555555;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  margin-bottom: 2px;
}
.grouped-horiz-histo-wrap .horiz-histo-wrap .histo-label {
  display: flex;
  justify-content: flex-end;
}
.grouped-horiz-histo-wrap .horiz-histo-wrap .histo-label *:last-child {
  font-size: 10px;
}
.grouped-horiz-histo-wrap .horiz-histo-wrap .histo-bar .histo-bar-fill {
  min-width: 10px;
}

.pie-chart-wrap {
  position: relative;
}
.pie-chart-wrap .rv-discrete-color-legend {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
}

.grouped-bar-wrap {
  position: relative;
  height: inherit;
}
.grouped-bar-wrap .grouped-bar-legend {
  position: absolute;
  width: auto;
  height: auto;
  top: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.8);
  padding: 14px 10px;
}

.rv-discrete-color-legend-item {
  padding: 4px 10px;
  cursor: default;
}
.rv-discrete-color-legend-item em {
  display: inline-block;
  opacity: 0.4;
  margin: 0 4px;
}
.rv-discrete-color-legend-item b {
  opacity: 0.8;
}

.rv-xy-plot__axis__tick__text {
  font-size: 10px;
}

.x-axis-label, .y-axis-label {
  font-weight: 700;
  opacity: 0.8;
}

.rv-xy-plot__axis__line,
.rv-xy-plot__axis__ticks line {
  stroke: #cfcfcf;
}

.rv-xy-plot__grid-lines__line {
  stroke: #d5d5d5;
}

.rv-radial-chart__series--pie__slice,
.horiz-histo-wrap li,
.rv-xy-plot__series--bar rect {
  transition: opacity 0.2s ease;
  cursor: default;
}
.rv-radial-chart__series--pie__slice.hovered,
.horiz-histo-wrap li.hovered,
.rv-xy-plot__series--bar rect.hovered {
  opacity: 1;
}
.rv-radial-chart__series--pie__slice.not-hovered,
.horiz-histo-wrap li.not-hovered,
.rv-xy-plot__series--bar rect.not-hovered {
  opacity: 0.1 !important;
}

.rv-xy-plot__series--bar rect[height="0"] {
  height: 0.5px !important;
  transform: translateY(-0.5px);
}

div.togglecolumns {
  /* The switch - the box around the slider */
  /* Hide default HTML checkbox */
  /* The slider */
  /* Rounded sliders */
}
div.togglecolumns .switch {
  position: relative;
  display: inline-block;
  width: 90px;
  height: 34px;
}
div.togglecolumns .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
div.togglecolumns .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
div.togglecolumns .slider::before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
div.togglecolumns input:checked + .slider {
  background-color: #2196f3;
}
div.togglecolumns input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}
div.togglecolumns input:checked + .slider:before {
  -webkit-transform: translateX(56px);
  -ms-transform: translateX(56px);
  transform: translateX(56px);
}
div.togglecolumns .switchlabel {
  position: absolute;
  cursor: pointer;
  top: 22%;
  left: 40%;
  z-index: 1;
}
div.togglecolumns input:checked + .slider + .switchlabel {
  top: 22%;
  left: 10%;
  color: white;
}
div.togglecolumns .slider.round {
  border-radius: 34px;
}
div.togglecolumns .slider.round:before {
  border-radius: 50%;
}

.login-page {
  min-height: 100vh;
  display: flex;
  flex-flow: row nowrap;
}
.login-page .left-block {
  background: #ffffff;
  box-shadow: 0 0 30px -2px rgba(33, 33, 33, 0.2);
  width: 50%;
  min-width: 300px;
  padding: 80px 0 40px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
}
@media only screen and (max-width: 820px) {
  .login-page .left-block {
    width: 100%;
  }
}
.login-page .right-block {
  background: rgba(219, 55, 57, 0.92);
  width: 50%;
  position: relative;
  z-index: 1;
}
@media only screen and (max-width: 820px) {
  .login-page .right-block {
    display: none;
  }
}
.login-page .right-block img {
  opacity: 0.1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.login-form {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
}
.login-form > div {
  padding: 20px;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
}
.login-form .error,
.login-form .warning {
  color: #db3739;
  font-weight: 700;
  text-align: right;
  margin-bottom: 14px;
}
.login-form .warning {
  color: #eea541;
}
.login-form .buttons {
  margin-top: 6px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.login-form img {
  height: 60px;
  margin-top: 60px;
}

.reset header {
  align-self: flex-start;
}
.reset .left-block {
  width: 100%;
}
.reset .login-form {
  max-width: 500px;
}
.reset .login-form > div {
  padding-left: 0;
  padding-right: 0;
}
.reset .login-form .text-conf {
  margin-top: -8px;
  font-size: 18px;
  line-height: 1.6;
  display: inline-block;
}

.home-page h2.switcher {
  margin-top: 30px;
  display: inline-block;
  float: right;
}
.home-page h2.switcher a {
  font-size: 18px;
  color: #888888;
  margin-right: 20px;
}
.home-page h2.switcher a.active {
  color: #db3739;
}
@media only screen and (max-width: 820px) {
  .home-page h2.switcher {
    margin-top: 0;
    margin-bottom: 10px;
    display: block;
    float: none;
  }
}

.profile-styles .buttons:not(:empty) {
  display: flex;
  justify-content: flex-end;
}
.profile-styles .profile-main-content li:not(:last-of-type) {
  margin-bottom: 16px;
}
.profile-styles .profile-main-content.read label {
  text-align: right;
  width: 100px;
  margin-right: 4px;
}
@media only screen and (max-width: 820px) {
  .profile-styles .profile-main-content.read label {
    width: 100%;
    text-align: left;
    margin-right: 0;
  }
}
.profile-styles .profile-main-content.read.larger li {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
}
.profile-styles .profile-main-content.read.larger label {
  width: 260px;
}
@media only screen and (max-width: 820px) {
  .profile-styles .profile-main-content.read.larger label {
    width: 100%;
  }
}
.profile-styles .profile-main-content.read.larger b {
  width: calc(100% - 264px);
}
@media only screen and (max-width: 820px) {
  .profile-styles .profile-main-content.read.larger b {
    width: 100%;
  }
}
.profile-styles .profile-main-content .note-content label,
.profile-styles .profile-main-content .note-file label {
  width: auto;
  text-align: left;
}
.profile-styles .profile-main-content .note-content label + *,
.profile-styles .profile-main-content .note-file label + * {
  display: block;
}
.profile-styles .profile-main-content .note-content textarea,
.profile-styles .profile-main-content .note-file textarea {
  min-width: 100%;
  max-width: 100%;
}
.profile-styles .profile-main-content:not(.read) .note-file {
  margin-top: 40px;
}
.profile-styles .profile-main-content .note-file > span {
  display: flex;
  flex-flow: column;
}
.profile-styles .profile-main-content .note-file > span span {
  font-size: 14px;
  color: #555555;
  margin-top: 8px;
}
.profile-styles .profile-main-content .note-file > span span:first-of-type {
  margin-top: 2px;
}
.profile-styles .profile-main-content .note-file > span span b, .profile-styles .profile-main-content .note-file > span span input {
  font-size: 14px;
  color: #212121;
  display: inline-block;
}
.profile-styles .profile-main-content .note-file > span span input {
  margin-left: 4px;
}
.profile-styles .profile-main-content .note-file > small {
  margin-left: 24px;
  margin-top: 8px;
}
.profile-styles .profile-main-content .note-file i {
  color: #888888;
}
.profile-styles .profile-main-content .checkboxes {
  display: flex;
}
.profile-styles .profile-main-content .checkboxes > *:not(:last-of-type) {
  margin-right: 20px;
}
.profile-styles .profile-main-content .checkboxes > * .ant-checkbox {
  margin-right: 4px;
}
.profile-styles .profile-main-content .checkboxes > * .ant-checkbox + span {
  font-size: 14px;
  font-weight: 700;
}
.profile-styles .profile-main-content + .buttons {
  padding-top: 20px;
}
.profile-styles .tags {
  margin-bottom: 26px;
  display: flex;
}
.profile-styles .tags li {
  font-size: 12px;
  font-weight: 700;
  color: #555555;
  background: rgba(136, 136, 136, 0.2);
  border-radius: 3px;
  box-shadow: 0 0 8px -2px rgba(33, 33, 33, 0.15);
  padding: 4px 20px;
}
.profile-styles .tags li:not(:last-of-type) {
  margin-right: 6px;
}
.profile-styles .tags li:empty,
.profile-styles .tags li > *:empty {
  display: none;
}

.request-details article.documents-box ul {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}
.request-details article.documents-box a {
  color: #212121;
}
.request-details article.documents-box li:not(.small),
.request-details article.documents-box li.small a,
.request-details article.documents-box li.small.not-done {
  width: calc(50% - 10px);
  margin-bottom: 8px;
  font-size: 14px;
  transition: all 0.2s ease;
  background: #ffffff;
  box-shadow: 0 0 8px -2px rgba(33, 33, 33, 0.15);
  border-radius: 3px;
  border: none !important;
  padding: 0;
  display: block;
  max-height: 48px;
  overflow: hidden;
}
@media only screen and (max-width: 820px) {
  .request-details article.documents-box li:not(.small),
  .request-details article.documents-box li.small a,
  .request-details article.documents-box li.small.not-done {
    width: 100%;
  }
}
.request-details article.documents-box li:not(.small) > *,
.request-details article.documents-box li.small a > *,
.request-details article.documents-box li.small.not-done > * {
  padding: calc(48px / 12) calc(48px / 2);
}
.request-details article.documents-box li:not(.small) .file-item-header,
.request-details article.documents-box li.small a .file-item-header,
.request-details article.documents-box li.small.not-done .file-item-header {
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.request-details article.documents-box li:not(.small) .file-item-header .material-icons:first-of-type,
.request-details article.documents-box li.small a .file-item-header .material-icons:first-of-type,
.request-details article.documents-box li.small.not-done .file-item-header .material-icons:first-of-type {
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  background: #db3739;
  border-radius: 3px;
  text-align: center;
  display: block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  margin-right: 18px;
}
.request-details article.documents-box li:not(.small) .file-item-header .material-icons:first-of-type.done,
.request-details article.documents-box li.small a .file-item-header .material-icons:first-of-type.done,
.request-details article.documents-box li.small.not-done .file-item-header .material-icons:first-of-type.done {
  background: #a2c754;
}
.request-details article.documents-box li:not(.small) .file-item-header .material-icons:last-of-type,
.request-details article.documents-box li.small a .file-item-header .material-icons:last-of-type,
.request-details article.documents-box li.small.not-done .file-item-header .material-icons:last-of-type {
  margin-left: auto;
  font-size: 18px;
  color: #555555;
  display: none;
}
.request-details article.documents-box li:not(.small) .file-item-body,
.request-details article.documents-box li.small a .file-item-body,
.request-details article.documents-box li.small.not-done .file-item-body {
  height: 100%;
  background: rgba(136, 136, 136, 0.1);
  display: flex;
  flex-flow: column;
  padding: 16px calc(48px / 2 + 18px + 6px) 16px calc(48px / 2 + 24px + 18px);
}
.request-details article.documents-box li:not(.small) .file-item-body i,
.request-details article.documents-box li.small a .file-item-body i,
.request-details article.documents-box li.small.not-done .file-item-body i {
  font-size: 12px;
  font-weight: 700;
  color: #555555;
  margin-bottom: 6px;
}
.request-details article.documents-box li:not(.small) .file-item-body > span,
.request-details article.documents-box li.small a .file-item-body > span,
.request-details article.documents-box li.small.not-done .file-item-body > span {
  display: flex;
  flex-flow: column wrap;
  font-size: 12px;
  color: #555555;
  margin-top: 8px;
}
.request-details article.documents-box li:not(.small) .file-item-body > span b, .request-details article.documents-box li:not(.small) .file-item-body > span input,
.request-details article.documents-box li.small a .file-item-body > span b,
.request-details article.documents-box li.small a .file-item-body > span input,
.request-details article.documents-box li.small.not-done .file-item-body > span b,
.request-details article.documents-box li.small.not-done .file-item-body > span input {
  font-size: 12px;
  color: #212121;
  margin-top: 2px;
  display: inline-block;
}
.request-details article.documents-box li:not(.small).opened,
.request-details article.documents-box li.small a.opened,
.request-details article.documents-box li.small.not-done.opened {
  max-height: 1000px;
}
.request-details article.documents-box li:not(.small).opened .material-icons:last-of-type,
.request-details article.documents-box li.small a.opened .material-icons:last-of-type,
.request-details article.documents-box li.small.not-done.opened .material-icons:last-of-type {
  transform: rotate(180deg);
}
.request-details article.documents-box h3 + small {
  font-size: 14px;
  color: #888888;
  display: inline-block;
  padding-left: 3px;
  transform: translateY(-30px);
  margin-bottom: 5px;
}
.request-documents-modal ul {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}
.request-documents-modal a {
  color: #212121;
}
.request-documents-modal li:not(.small),
.request-documents-modal li.small a,
.request-documents-modal li.small.not-done {
  width: calc(50% - 10px);
  margin-bottom: 8px;
  font-size: 14px;
  transition: all 0.2s ease;
  background: #ffffff;
  box-shadow: 0 0 8px -2px rgba(33, 33, 33, 0.15);
  border-radius: 3px;
  border: none !important;
  padding: 0;
  display: block;
  max-height: 48px;
  overflow: hidden;
}
@media only screen and (max-width: 820px) {
  .request-documents-modal li:not(.small),
  .request-documents-modal li.small a,
  .request-documents-modal li.small.not-done {
    width: 100%;
  }
}
.request-documents-modal li:not(.small) > *,
.request-documents-modal li.small a > *,
.request-documents-modal li.small.not-done > * {
  padding: calc(48px / 12) calc(48px / 2);
}
.request-documents-modal li:not(.small) .file-item-header,
.request-documents-modal li.small a .file-item-header,
.request-documents-modal li.small.not-done .file-item-header {
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.request-documents-modal li:not(.small) .file-item-header .material-icons:first-of-type,
.request-documents-modal li.small a .file-item-header .material-icons:first-of-type,
.request-documents-modal li.small.not-done .file-item-header .material-icons:first-of-type {
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  background: #db3739;
  border-radius: 3px;
  text-align: center;
  display: block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  margin-right: 18px;
}
.request-documents-modal li:not(.small) .file-item-header .material-icons:first-of-type.done,
.request-documents-modal li.small a .file-item-header .material-icons:first-of-type.done,
.request-documents-modal li.small.not-done .file-item-header .material-icons:first-of-type.done {
  background: #a2c754;
}
.request-documents-modal li:not(.small) .file-item-header .material-icons:last-of-type,
.request-documents-modal li.small a .file-item-header .material-icons:last-of-type,
.request-documents-modal li.small.not-done .file-item-header .material-icons:last-of-type {
  margin-left: auto;
  font-size: 18px;
  color: #555555;
  display: none;
}
.request-documents-modal li:not(.small) .file-item-body,
.request-documents-modal li.small a .file-item-body,
.request-documents-modal li.small.not-done .file-item-body {
  height: 100%;
  background: rgba(136, 136, 136, 0.1);
  display: flex;
  flex-flow: column;
  padding: 16px calc(48px / 2 + 18px + 6px) 16px calc(48px / 2 + 24px + 18px);
}
.request-documents-modal li:not(.small) .file-item-body i,
.request-documents-modal li.small a .file-item-body i,
.request-documents-modal li.small.not-done .file-item-body i {
  font-size: 12px;
  font-weight: 700;
  color: #555555;
  margin-bottom: 6px;
}
.request-documents-modal li:not(.small) .file-item-body > span,
.request-documents-modal li.small a .file-item-body > span,
.request-documents-modal li.small.not-done .file-item-body > span {
  display: flex;
  flex-flow: column wrap;
  font-size: 12px;
  color: #555555;
  margin-top: 8px;
}
.request-documents-modal li:not(.small) .file-item-body > span b, .request-documents-modal li:not(.small) .file-item-body > span input,
.request-documents-modal li.small a .file-item-body > span b,
.request-documents-modal li.small a .file-item-body > span input,
.request-documents-modal li.small.not-done .file-item-body > span b,
.request-documents-modal li.small.not-done .file-item-body > span input {
  font-size: 12px;
  color: #212121;
  margin-top: 2px;
  display: inline-block;
}
.request-documents-modal li:not(.small).opened,
.request-documents-modal li.small a.opened,
.request-documents-modal li.small.not-done.opened {
  max-height: 1000px;
}
.request-documents-modal li:not(.small).opened .material-icons:last-of-type,
.request-documents-modal li.small a.opened .material-icons:last-of-type,
.request-documents-modal li.small.not-done.opened .material-icons:last-of-type {
  transform: rotate(180deg);
}
.request-documents-modal li a,
.request-documents-modal li {
  width: 100% !important;
}
.request-documents-modal .done a {
  background: rgba(162, 199, 84, 0.1) !important;
  box-shadow: none !important;
}
.request-documents-modal .not-done {
  background: rgba(219, 55, 57, 0.1) !important;
  box-shadow: none !important;
}
.request-documents-modal .ant-modal-footer {
  display: none;
}

.request-details .status-bar {
  padding-top: 20px;
  margin-bottom: 20px;
}
.request-details .status-bar .split-block {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-right: 20px;
  margin-bottom: 20px;
}
@media only screen and (max-width: 820px) {
  .request-details .status-bar .split-block {
    flex-flow: column-reverse;
    align-items: flex-start;
  }
}
.request-details .status-bar article.buttons {
  margin-bottom: 20px;
}
.request-details .status-bar article.buttons .button {
  justify-content: center;
  text-align: center;
}
.request-details .timeline {
  min-width: 300px;
}
@media only screen and (max-width: 820px) {
  .request-details .timeline {
    padding-top: 10px;
  }
}
.request-details .timeline * {
  font-family: "Roboto", sans-serif;
}
.request-details .timeline .ant-timeline {
  transform: translateY(15px);
}
.request-details .timeline .greyed .ant-timeline-item-head,
.request-details .timeline .greyed .ant-timeline-item-content {
  color: rgba(136, 136, 136, 0.6) !important;
  border-color: rgba(136, 136, 136, 0.6) !important;
}
.request-details .box.notification {
  font-size: 14px;
  background: rgba(219, 55, 57, 0.1);
  box-shadow: none;
  border-radius: 3px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px 30px;
}
.request-details .box.notification a {
  font-weight: 700;
}
.request-details .status-box {
  width: 100%;
  margin-left: 40px;
  margin-bottom: 0;
}
.request-details .status-box .buttons {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}
.request-details .status-box .assignees li {
  display: flex;
}
.request-details .status-box .assignees li:not(:first-of-type) {
  margin-top: 10px;
}
@media only screen and (max-width: 820px) {
  .request-details .status-box .assignees li {
    display: block;
  }
}
.request-details .status-box .assignees label {
  margin-right: 8px;
  width: 160px;
  text-align: right;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 0;
}
.request-details .status-box .assignees label i {
  font-size: 14px;
}
.request-details .status-box .assignees label small {
  font-size: 12px;
  color: #888888;
}
@media only screen and (max-width: 820px) {
  .request-details .status-box .assignees label {
    width: 100%;
    text-align: left;
    align-items: flex-start;
    margin-bottom: 6px;
  }
}
.request-details .status-box .assignees b {
  margin-top: -2px;
}
.request-details .status-box .assignees .ant-select {
  width: calc(100% - 168px);
}
.request-details .status-box .assignees .ant-select .ant-select-selection-selected-value {
  font-size: 14px;
}
@media only screen and (max-width: 820px) {
  .request-details .status-box .assignees .ant-select {
    width: 100%;
  }
}
.request-details .status-box .assignees .button {
  font-size: 14px;
}
.request-details .buttons.footer {
  margin-top: 20px;
  margin-bottom: -40px;
  padding: 10px 0 12px;
  border-top: 1px solid rgba(136, 136, 136, 0.24);
}
@media only screen and (max-width: 820px) {
  .request-details .buttons.footer {
    flex-flow: row wrap;
  }
  .request-details .buttons.footer .button {
    width: 100%;
    margin: 6px 0;
    justify-content: center;
  }
}
.request-details .analyzes-buttons {
  padding-top: 5px;
  margin-bottom: 30px;
  margin-top: -20px;
}
.request-details .analyzes-buttons .button {
  font-size: 14px;
}
.request-details .analyzes-box h3 {
  margin-bottom: 10px;
}
.request-details .analyzes-list {
  display: flex;
}
@media only screen and (max-width: 820px) {
  .request-details .analyzes-list {
    flex-flow: column;
  }
}
.request-details .analyzes-list .analyze-item {
  background: rgba(219, 55, 57, 0.1);
  box-shadow: none;
  border-radius: 3px;
  width: 100%;
  margin-bottom: 0;
  padding: 30px 30px 20px;
  display: flex;
  flex-flow: column;
}
@media only screen and (max-width: 820px) {
  .request-details .analyzes-list .analyze-item {
    margin-bottom: 10px;
  }
}
.request-details .analyzes-list .analyze-item:not(:last-of-type) {
  margin-right: 20px;
}
.request-details .analyzes-list .analyze-item h4 {
  font-size: 16px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  text-decoration: underline;
  margin-bottom: 10px;
}
@media only screen and (max-width: 820px) {
  .request-details .analyzes-list .analyze-item h4 {
    text-align: center;
  }
}
.request-details .analyzes-list .analyze-item h4 a {
  color: #db3739;
}
.request-details .analyzes-list .analyze-item .button {
  font-size: 12px;
  margin-top: auto;
  justify-content: center;
}
.request-details .analyzes-list .analyze-item.green {
  background: rgba(137, 175, 57, 0.1);
}
.request-details .analyzes-list .analyze-item.green h4 a {
  color: #a2c754;
}
.request-details .analyzes-list .analyze-item.grey {
  background: rgba(111, 111, 111, 0.1);
}
.request-details .analyzes-list .analyze-item.grey h4 a {
  color: #888888;
}
.request-details .analyzes-list .analyze-item:empty {
  display: none;
}
.request-details .recommendations-box li {
  margin-bottom: 16px;
}
.request-details .recommendations-box .doc {
  display: flex;
  flex-flow: column;
}
.request-details .recommendations-box .doc > span {
  font-size: 14px;
  color: #555555;
  margin-top: 8px;
}
.request-details .recommendations-box .doc > span:first-of-type {
  margin-top: 2px;
}
.request-details .recommendations-box .doc > span b, .request-details .recommendations-box .doc > span input {
  font-size: 14px;
  color: #212121;
  display: inline-block;
}
.request-details .recommendations-box .doc > span input {
  margin-left: 4px;
}
.request-details .recommendations-box .doc > small {
  margin-left: 24px;
  margin-top: 8px;
}
.request-details .recommendations-box .buttons {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}
.request-details .recommendations-box.read-only label {
  margin-right: 4px;
  margin-bottom: 0;
}
.request-details .recommendations-box.read-only i {
  color: #888888;
}
.request-details .recommendations-box.read-only .doc {
  flex-flow: row;
}
.request-details .help-links {
  margin-top: 30px;
  padding: 40px 30px;
  border: 2px solid rgba(136, 136, 136, 0.1);
  background: rgba(255, 255, 255, 0.24);
  border-radius: 20px;
}
.request-details .help-links h4 {
  font-size: 24px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: rgba(136, 136, 136, 0.6);
  margin-bottom: 10px;
}
.request-details .help-links .links {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: stretch;
}
@media only screen and (max-width: 820px) {
  .request-details .help-links .links {
    flex-flow: column;
  }
}
.request-details .help-links a {
  width: calc(33% - 5px);
  padding: 10px 20px;
  margin-bottom: 10px;
}
.request-details .help-links a:not(:nth-of-type(3n)) {
  margin-right: 10px;
}
@media only screen and (max-width: 820px) {
  .request-details .help-links a {
    width: 100%;
    margin-right: 0 !important;
  }
}

.doc-popup {
  font-size: 16px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  color: #ffffff;
  background: #db3739;
  border-radius: 3px;
  box-shadow: 0 0 15px 6px rgba(33, 33, 33, 0.2);
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 10;
}
.doc-popup .material-icons {
  font-size: 20px;
  margin-right: 8px;
}
.doc-popup:after {
  content: "";
  display: block;
  background: rgba(219, 55, 57, 0.24);
  border-radius: 6px;
  position: absolute;
  top: -6px;
  bottom: -6px;
  left: -6px;
  right: -6px;
  z-index: -1;
}
.doc-popup:hover {
  opacity: 1;
  transform: translateY(-2px);
  box-shadow: 0 0 20px 6px rgba(33, 33, 33, 0.22);
  color: #ffffff;
}

.request-presubmit-modal input[type=text] {
  width: 100px;
  text-align: center;
  margin-top: 4px;
  margin-bottom: 12px;
}

.request-approbation-modal .ctn {
  margin-bottom: 16px;
}

.create-batch-modal {
  width: 700px !important;
}
.create-batch-modal .ctn {
  margin-bottom: 16px;
}
.ctn.file {
  margin-bottom: 28px;
}
.ctn.file > span, .ctn.file input {
  display: block;
}
.ctn.file small {
  font-size: 10px;
  display: inline-block;
  margin-top: 6px;
}

.create-batch-modal .ant-transfer-list-body-search-wrapper {
  padding: 4px 12px;
  background: rgba(136, 136, 136, 0.05);
  border-bottom: 1px solid rgba(136, 136, 136, 0.24);
}
.create-batch-modal .ant-transfer-list-body-search-wrapper input[type=text] {
  font-size: 12px;
  padding: 4px 10px;
  height: 28px;
  line-height: 28px;
  border-color: rgba(136, 136, 136, 0.24);
}
.create-batch-modal .ant-transfer-list-body-search-wrapper .ant-transfer-list-search-action {
  top: 4px;
  bottom: 4px;
}
.create-batch-modal .ant-transfer-list {
  width: 298px;
  height: 300px;
  padding-top: 36px;
}
.create-batch-modal .ant-transfer-list .ant-checkbox-wrapper {
  margin-right: 4px;
  margin-bottom: 0;
}
.create-batch-modal .ant-transfer-list .ant-transfer-list-header {
  font-size: 12px;
  height: 36px;
}
.create-batch-modal .ant-transfer-list .ant-transfer-list-header .ant-transfer-list-header-selected {
  font-weight: 700;
  transform: translateY(-1px);
  display: inline-block;
}
.create-batch-modal .ant-transfer-list .ant-transfer-list-body {
  padding-top: 36px;
}
.create-batch-modal .ant-transfer-operation {
  width: 40px;
}
.create-batch-modal .ant-transfer-operation button {
  width: 40px;
  padding: 6px 0;
}

body[data-financial=true] h1 small {
  font-size: 14px;
  position: absolute;
  left: 2px;
  bottom: -12px;
}
body[data-financial=true] .top-link {
  left: 70px;
}
body[data-financial=true] .top-link:before {
  content: "/";
  display: inline-block;
  margin: 0 20px;
  pointer-events: none;
}
body[data-financial=true] #root > .container {
  width: 100% !important;
  max-width: none !important;
  padding: 0 !important;
}
body[data-financial=true] #root > .container > div .container::after {
  content: "";
  clear: both;
  display: table;
}
body[data-financial=true] #root > .container > div .container:last-of-type .button {
  margin-bottom: 0;
}
body[data-financial=true] .buttons-container {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 800px;
  margin-left: auto;
  margin-right: auto;
}
body[data-financial=true] .buttons-container > b {
  font-size: 16px;
  color: #555555;
  text-align: center;
  display: inline-block;
  width: 100%;
  margin-bottom: 10px;
}
body[data-financial=true] .buttons-container .wrap {
  display: flex;
  flex-flow: column;
}
body[data-financial=true] .buttons-container .wrap > div {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
body[data-financial=true] .buttons-container .wrap b {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  text-align: right;
  color: #888888;
  margin-right: 10px;
  width: 60px;
}
body[data-financial=true] .buttons-container .wrap .button-link {
  margin: 0;
  float: none;
  font-size: 14px;
  padding: 5px 15px;
  background: rgba(219, 55, 57, 0.1);
  color: #db3739;
}
body[data-financial=true] .buttons-container .wrap .button-link:not(:last-of-type) {
  margin-right: 10px;
}
body[data-financial=true] .buttons-container .wrap .button-link.validate {
  background: rgba(162, 199, 84, 0.24);
  color: #a2c754;
}
body[data-financial=true] .form-box.financial,
body[data-financial=true] .form-box.financial-summary {
  width: 100%;
  margin: 0;
}
body[data-financial=true] .top-select {
  margin-bottom: 12px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}
body[data-financial=true] .top-select span {
  font-size: 14px;
  font-weight: 700;
  margin-right: 4px;
}
body[data-financial=true] .top-select .ant-select {
  font-size: 14px;
  width: 90px;
}
body[data-financial=true] .button {
  width: auto;
  float: right;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 20px;
}
body[data-financial=true] .financial .table-header,
body[data-financial=true] .financial-summary .table-header {
  margin-bottom: 20px;
  min-width: 800px;
  margin-left: auto;
  margin-right: auto;
}
body[data-financial=true] .financial .table-header td .material-icons,
body[data-financial=true] .financial-summary .table-header td .material-icons {
  font-size: 14px;
  margin-left: 6px;
  transform: translateY(3px);
}
body[data-financial=true] .financial .table-header tbody td input,
body[data-financial=true] .financial-summary .table-header tbody td input {
  width: 100% !important;
  max-width: auto;
}
body[data-financial=true] .financial .table-header tbody tr td,
body[data-financial=true] .financial-summary .table-header tbody tr td {
  border-bottom: 1px solid rgba(136, 136, 136, 0.24);
}
body[data-financial=true] .financial .table-header tbody tr td.not-first,
body[data-financial=true] .financial-summary .table-header tbody tr td.not-first {
  background: none !important;
}
body[data-financial=true] .financial .table-header tr.dates td:not(:first-of-type),
body[data-financial=true] .financial-summary .table-header tr.dates td:not(:first-of-type) {
  text-align: center;
  font-size: 12px !important;
  font-weight: 700;
  background: rgba(219, 55, 57, 0.4);
  border-bottom: 1px solid #671213;
}
body[data-financial=true] .financial .table-header tr.dates td,
body[data-financial=true] .financial-summary .table-header tr.dates td {
  border-top: 2px solid #671213;
}
body[data-financial=true] .financial .table-header tr.dates + tr td,
body[data-financial=true] .financial-summary .table-header tr.dates + tr td {
  font-weight: 700;
  font-size: 12px !important;
  text-align: center;
  color: #212121 !important;
}
body[data-financial=true] .form-box.financial,
body[data-financial=true] .form-box.financial-summary {
  max-width: 1360px;
  margin: 20px auto 0;
  padding: 0 20px;
}
body[data-financial=true] .form-box.financial thead tr,
body[data-financial=true] .form-box.financial-summary thead tr {
  background: rgba(219, 55, 57, 0.4);
}
body[data-financial=true] .form-box.financial thead th[colspan="2"][rowspan="1"],
body[data-financial=true] .form-box.financial-summary thead th[colspan="2"][rowspan="1"] {
  border: 2px solid #671213;
}
body[data-financial=true] .form-box.financial thead tr:last-of-type th,
body[data-financial=true] .form-box.financial-summary thead tr:last-of-type th {
  font-size: 12px;
  opacity: 0.6;
  padding: 0px 8px 4px;
}
body[data-financial=true] .form-box.financial tbody th,
body[data-financial=true] .form-box.financial-summary tbody th {
  border: 2px solid #671213;
  color: #671213;
  background: #e36264;
  font-weight: 700;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
}
body[data-financial=true] .form-box.financial tbody tr,
body[data-financial=true] .form-box.financial-summary tbody tr {
  background: #ffffff;
}
body[data-financial=true] .form-box.financial tbody tr.financial-section-name,
body[data-financial=true] .form-box.financial-summary tbody tr.financial-section-name {
  background: #d5d5d5;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  color: #555555;
  border: 2px solid #671213;
}
body[data-financial=true] .form-box.financial tbody tr.financial-section-name td,
body[data-financial=true] .form-box.financial-summary tbody tr.financial-section-name td {
  padding: 14px 0;
}
body[data-financial=true] .form-box.financial tbody tr.financial-subsection-name,
body[data-financial=true] .form-box.financial-summary tbody tr.financial-subsection-name {
  background: rgba(136, 136, 136, 0.1);
  color: #555555;
  border: 2px solid #671213;
}
body[data-financial=true] .form-box.financial tbody tr.financial-subsection-name td,
body[data-financial=true] .form-box.financial-summary tbody tr.financial-subsection-name td {
  border: none;
  font-size: 12px;
  font-weight: 700;
  padding-top: 8px;
  padding-bottom: 8px;
}
body[data-financial=true] .form-box.financial tbody tr.financial-subsection-total,
body[data-financial=true] .form-box.financial-summary tbody tr.financial-subsection-total {
  background: #f6cecf;
}
body[data-financial=true] .form-box.financial tbody tr.financial-subsection-total td,
body[data-financial=true] .form-box.financial-summary tbody tr.financial-subsection-total td {
  font-size: 12px !important;
  padding-top: 8px;
  padding-bottom: 8px;
  vertical-align: bottom;
}
body[data-financial=true] .form-box.financial tbody tr.financial-subsection-total td input,
body[data-financial=true] .form-box.financial-summary tbody tr.financial-subsection-total td input {
  font-size: 12px !important;
}
body[data-financial=true] .form-box.financial tbody tr.financial-section-total,
body[data-financial=true] .form-box.financial-summary tbody tr.financial-section-total {
  border: 2px solid;
}
body[data-financial=true] .form-box.financial tbody tr.financial-section-total td,
body[data-financial=true] .form-box.financial-summary tbody tr.financial-section-total td {
  padding-top: 8px;
  padding-bottom: 8px;
  background: rgba(219, 55, 57, 0.4) !important;
  vertical-align: bottom;
}
body[data-financial=true] .form-box.financial tbody tr.total-tilte,
body[data-financial=true] .form-box.financial-summary tbody tr.total-tilte {
  border-top: 4px solid #671213;
  border-bottom: 4px solid #671213;
}
body[data-financial=true] .form-box.financial tbody tr.financial-total td,
body[data-financial=true] .form-box.financial-summary tbody tr.financial-total td {
  vertical-align: middle !important;
  background: rgba(136, 136, 136, 0.1);
}
body[data-financial=true] .form-box.financial tbody tr.financial-total:last-of-type td,
body[data-financial=true] .form-box.financial-summary tbody tr.financial-total:last-of-type td {
  border-top: 2px solid #671213;
  text-align: right !important;
}
body[data-financial=true] .form-box.financial tbody tr:not(.financial-section-name):not(.financial-subsection-name) td:first-of-type,
body[data-financial=true] .form-box.financial-summary tbody tr:not(.financial-section-name):not(.financial-subsection-name) td:first-of-type {
  background: #f2b9b9;
  color: #671213;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  vertical-align: middle !important;
}
body[data-financial=true] .form-box.financial tbody tr:not(.financial-section-name):not(.financial-subsection-name) td:nth-of-type(2),
body[data-financial=true] .form-box.financial-summary tbody tr:not(.financial-section-name):not(.financial-subsection-name) td:nth-of-type(2) {
  font-size: 14px;
  font-weight: 700;
}
body[data-financial=true] .form-box.financial tbody tr:not(.financial-section-name):not(.financial-subsection-name) td:not(:first-of-type):not(:nth-of-type(2)),
body[data-financial=true] .form-box.financial-summary tbody tr:not(.financial-section-name):not(.financial-subsection-name) td:not(:first-of-type):not(:nth-of-type(2)) {
  vertical-align: bottom;
}
body[data-financial=true] .form-box.financial tbody input,
body[data-financial=true] .form-box.financial-summary tbody input {
  width: 200px !important;
}
body[data-financial=true] .form-box.financial tbody input[disabled],
body[data-financial=true] .form-box.financial-summary tbody input[disabled] {
  background: none !important;
  border: none !important;
  padding: 0 !important;
  font-weight: 700;
}
body[data-financial=true] .form-box.financial td, body[data-financial=true] .form-box.financial th,
body[data-financial=true] .form-box.financial-summary td,
body[data-financial=true] .form-box.financial-summary th {
  border-right: 2px solid #671213;
}
body[data-financial=true] .form-box.financial.read-only input,
body[data-financial=true] .form-box.financial.read-only td span,
body[data-financial=true] .form-box.financial-summary input,
body[data-financial=true] .form-box.financial-summary td span {
  font-size: 14px !important;
  display: inline-block;
  width: 120px !important;
  min-width: 120px !important;
  max-width: 120px !important;
  text-align: right !important;
  vertical-align: middle !important;
}
body[data-financial=true] .form-box.financial.read-only .financial-total,
body[data-financial=true] .form-box.financial.read-only .financial-subsection-total,
body[data-financial=true] .form-box.financial.read-only .financial-section-total,
body[data-financial=true] .form-box.financial.read-only .financial-element,
body[data-financial=true] .form-box.financial-summary .financial-total,
body[data-financial=true] .form-box.financial-summary .financial-subsection-total,
body[data-financial=true] .form-box.financial-summary .financial-section-total,
body[data-financial=true] .form-box.financial-summary .financial-element {
  border-bottom: 1px solid rgba(136, 136, 136, 0.24);
}
body[data-financial=true] .form-box.financial.read-only .financial-total td:not(:nth-of-type(2)),
body[data-financial=true] .form-box.financial.read-only .financial-total td:not(:nth-of-type(2)) input,
body[data-financial=true] .form-box.financial.read-only .financial-subsection-total td:not(:nth-of-type(2)),
body[data-financial=true] .form-box.financial.read-only .financial-subsection-total td:not(:nth-of-type(2)) input,
body[data-financial=true] .form-box.financial.read-only .financial-section-total td:not(:nth-of-type(2)),
body[data-financial=true] .form-box.financial.read-only .financial-section-total td:not(:nth-of-type(2)) input,
body[data-financial=true] .form-box.financial.read-only .financial-element td:not(:nth-of-type(2)),
body[data-financial=true] .form-box.financial.read-only .financial-element td:not(:nth-of-type(2)) input,
body[data-financial=true] .form-box.financial-summary .financial-total td:not(:nth-of-type(2)),
body[data-financial=true] .form-box.financial-summary .financial-total td:not(:nth-of-type(2)) input,
body[data-financial=true] .form-box.financial-summary .financial-subsection-total td:not(:nth-of-type(2)),
body[data-financial=true] .form-box.financial-summary .financial-subsection-total td:not(:nth-of-type(2)) input,
body[data-financial=true] .form-box.financial-summary .financial-section-total td:not(:nth-of-type(2)),
body[data-financial=true] .form-box.financial-summary .financial-section-total td:not(:nth-of-type(2)) input,
body[data-financial=true] .form-box.financial-summary .financial-element td:not(:nth-of-type(2)),
body[data-financial=true] .form-box.financial-summary .financial-element td:not(:nth-of-type(2)) input {
  text-align: right !important;
  vertical-align: middle !important;
}
body[data-financial=true] .form-box.financial-summary table {
  background: rgba(136, 136, 136, 0.24);
  border: 2px solid #671213;
}
body[data-financial=true] .form-box.financial-summary td {
  font-weight: 400 !important;
}
body[data-financial=true] .form-box.financial-summary .row-total td {
  font-weight: 700 !important;
}
body[data-financial=true] .form-box.financial-summary .row-total td.cell-title:first-of-type {
  text-align: right !important;
}
body[data-financial=true] .form-box.financial-summary .row-data td,
body[data-financial=true] .form-box.financial-summary .row-total td {
  border-bottom: 1px solid rgba(136, 136, 136, 0.24);
}
body[data-financial=true] .form-box.financial-summary .row-data td:first-of-type,
body[data-financial=true] .form-box.financial-summary .row-total td:first-of-type {
  text-align: left !important;
  vertical-align: middle !important;
  min-width: 200px;
  border-color: rgba(103, 18, 19, 0.24);
}
body[data-financial=true] .form-box.financial-summary .row-data td,
body[data-financial=true] .form-box.financial-summary .row-total td {
  text-align: right !important;
  vertical-align: middle !important;
}
body[data-financial=true] .form-box.financial-summary .row-data td:nth-of-type(7),
body[data-financial=true] .form-box.financial-summary .row-data td:nth-of-type(8),
body[data-financial=true] .form-box.financial-summary .row-data td:nth-of-type(9),
body[data-financial=true] .form-box.financial-summary .row-total td:nth-of-type(7),
body[data-financial=true] .form-box.financial-summary .row-total td:nth-of-type(8),
body[data-financial=true] .form-box.financial-summary .row-total td:nth-of-type(9) {
  background: rgba(238, 165, 65, 0.24);
}
body[data-financial=true] .form-box.financial-summary .row-data.first-lines td:nth-of-type(4),
body[data-financial=true] .form-box.financial-summary .row-data.first-lines td:nth-of-type(5),
body[data-financial=true] .form-box.financial-summary .row-data.first-lines td:nth-of-type(6), body[data-financial=true] .form-box.financial-summary .row-data.second-lines td:nth-of-type(4),
body[data-financial=true] .form-box.financial-summary .row-data.second-lines td:nth-of-type(5),
body[data-financial=true] .form-box.financial-summary .row-data.second-lines td:nth-of-type(6),
body[data-financial=true] .form-box.financial-summary .row-total.first-lines td:nth-of-type(4),
body[data-financial=true] .form-box.financial-summary .row-total.first-lines td:nth-of-type(5),
body[data-financial=true] .form-box.financial-summary .row-total.first-lines td:nth-of-type(6),
body[data-financial=true] .form-box.financial-summary .row-total.second-lines td:nth-of-type(4),
body[data-financial=true] .form-box.financial-summary .row-total.second-lines td:nth-of-type(5),
body[data-financial=true] .form-box.financial-summary .row-total.second-lines td:nth-of-type(6) {
  background: rgba(162, 199, 84, 0.24);
}
body[data-financial=true] .form-box.financial-summary .row-data.soldes td:nth-of-type(5),
body[data-financial=true] .form-box.financial-summary .row-data.soldes td:nth-of-type(6),
body[data-financial=true] .form-box.financial-summary .row-total.soldes td:nth-of-type(5),
body[data-financial=true] .form-box.financial-summary .row-total.soldes td:nth-of-type(6) {
  background: #212121;
}
body[data-financial=true] .form-box.financial-summary .row-data.others td:nth-of-type(5),
body[data-financial=true] .form-box.financial-summary .row-data.others td:nth-of-type(6),
body[data-financial=true] .form-box.financial-summary .row-total.others td:nth-of-type(5),
body[data-financial=true] .form-box.financial-summary .row-total.others td:nth-of-type(6) {
  background: #212121;
}
body[data-financial=true] .form-box.financial-summary .row-total.first-lines td:not(:first-of-type) {
  background: rgba(238, 165, 65, 0.24) !important;
}
body[data-financial=true] .form-box.financial-summary .row-total.soldes td,
body[data-financial=true] .form-box.financial-summary .row-total.others:last-of-type td {
  background: rgba(238, 165, 65, 0.24);
}

.faq-wrap {
  display: flex;
  flex-flow: row-reverse;
  justify-content: space-between;
  align-items: flex-start;
}
.faq-wrap .faq-main-box {
  width: 65%;
}
.faq-wrap .faq-main-box li:not(:last-of-type) {
  border-bottom: 1px solid rgba(136, 136, 136, 0.24);
}
.faq-wrap .faq-main-box .title-qa {
  cursor: pointer;
  transition: all 0.2s ease;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  padding: 20px 0;
  display: flex;
  align-items: center;
}
.faq-wrap .faq-main-box .title-qa .material-icons {
  font-size: 18px;
  color: #888888;
  margin-right: 10px;
}
.faq-wrap .faq-main-box .title-qa:hover {
  opacity: 0.8;
}
.faq-wrap .faq-main-box .content-qa {
  padding: 2px 0 20px 28px;
  margin-top: -10px;
}
.faq-wrap .faq-main-box .content-qa br {
  content: " ";
  display: block;
  margin: 12px 0 0;
}
.faq-wrap .faq-second-box {
  width: 35%;
  padding: 30px 20px;
}
@media only screen and (max-width: 820px) {
  .faq-wrap .faq-second-box {
    padding-top: 10px;
  }
}
.faq-wrap .faq-second-box p:first-of-type {
  margin-bottom: 12px;
  font-family: "Montserrat", sans-serif;
  color: #555555;
}
.faq-wrap .faq-second-box p:last-of-type, .faq-wrap .faq-second-box ul {
  font-size: 14px;
  color: #888888;
}
.faq-wrap .faq-second-box ul {
  margin-top: 16px;
}
.faq-wrap .faq-second-box ul li {
  margin-bottom: 12px;
  font-size: 12px;
}
.faq-wrap .faq-second-box ul b {
  display: block;
  font-size: 14px;
  margin-top: 4px;
}
.faq-wrap .faq-second-box ul b small {
  font-size: 12px;
  margin-top: 2px;
  display: inline-block;
}
@media only screen and (max-width: 820px) {
  .faq-wrap {
    flex-flow: column;
  }
  .faq-wrap article {
    width: 100% !important;
  }
}

.dashboard-wrap .dashboard-main-nav {
  width: 100%;
  margin-bottom: 40px;
  margin-top: -20px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  background: rgba(136, 136, 136, 0.1);
}
@media only screen and (max-width: 820px) {
  .dashboard-wrap .dashboard-main-nav {
    flex-flow: column;
    margin-top: 0;
  }
}
.dashboard-wrap .dashboard-main-nav a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33.33333%;
  padding: 10px 4px;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: #888888;
}
@media only screen and (max-width: 820px) {
  .dashboard-wrap .dashboard-main-nav a {
    width: 100%;
  }
}
.dashboard-wrap .dashboard-main-nav a.active {
  color: #db3739;
  background: #fae4e4;
  opacity: 1 !important;
}
.dashboard-wrap .dashboard-main-nav a .material-icons {
  font-size: 18px;
  margin-right: 10px;
}
.reporting-page .buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.reporting-page .table-box {
  margin-top: 40px;
}
.reporting-page .error {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  color: #888888;
  padding: 40px 30px;
}
.reporting-page .ant-skeleton {
  padding: 20px 30px;
}

.stats-page {
  position: relative;
}
.stats-page h1 {
  position: relative;
}
.stats-page h1 .material-icons {
  position: absolute;
  top: 55%;
  right: -50px;
  transform: translateY(-50%);
  cursor: pointer;
  transition: all 0.2s ease;
}
.stats-page h1 .material-icons:hover {
  opacity: 0.8;
}
@media only screen and (max-width: 820px) {
  .stats-page h1 .material-icons {
    display: none;
  }
}

.stats-filters {
  width: 100%;
  margin: 0 auto;
  padding-bottom: 0 !important;
}
.stats-filters h2 {
  position: relative;
  z-index: 11;
  font-size: 16px;
  color: #ffffff;
  background: #db3739;
  display: flex;
  align-items: center;
  padding: 10px 10px;
  cursor: pointer;
  transition: all 0.2s ease;
}
.stats-filters h2 .material-icons {
  font-size: 18px;
  margin-left: auto;
}
.stats-filters h2 b {
  background: #ffffff;
  color: #db3739;
  font-size: 12px;
  font-weight: 900;
  display: block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border-radius: 50%;
  margin-right: 10px;
}
.stats-filters .filters-wrap {
  position: absolute;
  z-index: 10;
  top: 0;
  overflow: hidden;
  padding: 0 10px;
  max-height: 0;
  width: 100%;
  background: #ffffff;
  box-shadow: 0 0 30px -2px rgba(33, 33, 33, 0.1);
  border-radius: 3px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}
@media only screen and (max-width: 820px) {
  .stats-filters .filters-wrap {
    flex-flow: column wrap;
  }
}
.stats-filters .filters-wrap.opened {
  max-height: 10000px;
  padding-top: 70px;
}
.stats-filters .filters-wrap > * {
  width: calc(50% - 20px);
}
.stats-filters .filters-wrap > *:last-child {
  margin-bottom: 10px;
}
@media only screen and (max-width: 820px) {
  .stats-filters .filters-wrap > * {
    width: 100%;
    margin-bottom: 10px;
  }
}
.stats-filters .filters-wrap > * .ctn, .stats-filters .filters-wrap > * .ant-radio-group {
  width: 100%;
  margin-bottom: 20px;
}
.stats-filters .filters-wrap > * .ctn label, .stats-filters .filters-wrap > * .ant-radio-group label {
  display: block;
  font-size: 12px;
  font-weight: 700;
}
.stats-filters .filters-wrap > * .ctn .ant-input,
.stats-filters .filters-wrap > * .ctn .ant-select, .stats-filters .filters-wrap > * .ant-radio-group .ant-input,
.stats-filters .filters-wrap > * .ant-radio-group .ant-select {
  display: block;
}
.stats-filters .filters-wrap > * .ctn .ant-radio + span, .stats-filters .filters-wrap > * .ant-radio-group .ant-radio + span {
  margin-left: 10px;
  color: #888888;
}
.stats-filters .filters-buttons {
  width: 100% !important;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.stats-graphs section {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: space-between;
}
.stats-graphs section:not(:first-of-type) {
  margin-top: 40px;
}
@media only screen and (max-width: 820px) {
  .stats-graphs section {
    flex-flow: column wrap;
  }
}
.stats-graphs section .section-title {
  width: 100%;
  text-align: center;
  font-size: 36px;
  background: rgba(219, 55, 57, 0.1);
  color: #db3739;
  padding: 10px;
  line-height: 1.4;
}
.stats-graphs .graph-item {
  padding: 30px 0;
  width: 100%;
}
.stats-graphs .graph-item h2 {
  margin-bottom: 10px;
  line-height: 1.4;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px 10px;
  background: rgba(136, 136, 136, 0.1);
}
.stats-graphs .graph-item h2 .material-icons:first-child {
  margin-right: 20px;
}
.stats-graphs .graph-item h2 .material-icons:last-child {
  display: block;
  font-size: 16px;
  cursor: pointer;
  margin-left: 25px;
  color: #888888;
}
.ant-tooltip-inner {
  font-size: 12px;
  min-height: 24px;
}

.stats-graphs .graph-item.centered {
  text-align: center;
}
.stats-graphs .graph-item.upper-item {
  border: 4px solid rgba(136, 136, 136, 0.1);
  background: rgba(136, 136, 136, 0.05);
}
.stats-graphs .graph-item.upper-item h2 {
  justify-content: center;
  background: none;
  padding: 0;
}
.stats-graphs .graph-item.upper-item .error {
  padding: 0 !important;
  background: none !important;
}
.stats-graphs .graph-item.upper-item * {
  color: #888888;
  margin: 0;
}
.stats-graphs .graph-item.unavailable-graph {
  line-height: 1.4;
}
.stats-graphs .graph-item.unavailable-graph .error {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 30px 20px;
  background: rgba(136, 136, 136, 0.1);
}
.stats-graphs .graph-item.unavailable-graph .error .material-icons {
  font-size: 42px;
  margin-bottom: 10px;
  color: #888888;
}
.stats-graphs .graph-item.unavailable-graph h3 {
  font-size: 16px;
  margin-bottom: 6px;
}
.stats-graphs .graph-item.unavailable-graph p {
  font-size: 10px;
}
@media only screen and (min-width: 820px) {
  .stats-graphs .graph-item.col-1 {
    width: calc(25% - 20px);
  }
  .stats-graphs .graph-item.col-2 {
    width: calc(50% - 20px);
  }
  .stats-graphs .graph-item.col-3 {
    width: calc(75% - 20px);
  }
  .stats-graphs .graph-item.col-4 {
    width: 100%;
  }
}

.status-page .status-filters {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-bottom: 40px;
}
.status-page .status-filters .ant-select-selection--multiple {
  padding-bottom: 0;
}
.status-page .status-filters .ctn:nth-of-type(1) {
  width: 340px;
}
.status-page .status-filters .ctn:nth-of-type(2) {
  width: calc(100% - 20px - 340px - 140px);
}
.status-page .status-filters .ctn:nth-of-type(3) {
  width: 140px;
}
.status-page .status-filters .ant-btn {
  margin-top: 10px;
  margin-left: auto;
}
@media only screen and (max-width: 820px) {
  .status-page .status-filters {
    flex-flow: column;
  }
  .status-page .status-filters .ctn {
    width: 100% !important;
    margin-bottom: 10px;
  }
}
.status-page .status-graph {
  min-width: 1000px;
  position: relative;
}
.status-page .status-header {
  display: flex;
  flex: row nowrap;
  padding: 12px 0 6px;
  margin-bottom: 8px;
  border-bottom: 4px solid #db3739;
  border-top: 4px solid #db3739;
  background: rgba(219, 55, 57, 0.1);
}
.status-page .status-header .header-buffer {
  width: 200px;
  font-family: "Montserrat", sans-serif;
  color: rgba(33, 33, 33, 0.6);
  font-weight: 700;
  font-size: 12px;
  padding-left: 10px;
}
.status-page .status-header .header-buffer .material-icons {
  left: 40px !important;
}
.status-page .status-header .header-buffer, .status-page .status-header .section-main-title {
  position: relative;
  cursor: pointer;
}
.status-page .status-header .header-buffer .material-icons, .status-page .status-header .section-main-title .material-icons {
  position: absolute;
  left: 50%;
  top: -10px;
  transform: translateX(-50%);
  font-size: 14px;
}
.status-page .status-header .header-sections {
  width: calc(100% - 200px);
  display: flex;
  flex-flow: row nowrap;
}
.status-page .status-header .header-sections .section {
  text-align: center;
}
.status-page .status-header .header-sections .section .section-main-title {
  font-size: 14px;
}
.status-page .status-header .header-sections .section-analysis {
  width: 150px !important;
}
.status-page .status-header .header-sections .section-analysis .section-artefacts {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-around;
  padding: 5px 0;
  padding-bottom: 0;
}
.status-page .status-header .header-sections .section-analysis .section-artefacts .material-icons {
  font-size: 16px;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  line-height: 25px;
  border: none !important;
}
.status-page .status-header .header-sections .section-analysis .section-artefacts .material-icons {
  color: rgba(146, 26, 28, 0.6);
  background: rgba(146, 26, 28, 0.1);
}
.status-page .status-body {
  position: relative;
}
.status-page .status-body .status-line {
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  flex-flow: row nowrap;
  height: 45px;
}
.status-page .status-body .status-line .status-line-title {
  display: flex;
  flex-flow: column;
  justify-content: center;
}
.status-page .status-body .status-line .status-line-title * {
  display: inline-block;
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 10px;
  padding-left: 10px;
  font-family: "Montserrat", sans-serif;
  color: #888888;
}
.status-page .status-body .status-line .status-line-title span {
  font-size: 12px;
  font-weight: 700;
  text-decoration: underline;
}
.status-page .status-body .status-line .status-line-title small {
  font-size: 10px;
  font-weight: 300;
}
.status-page .status-body .status-line .status-line-sections {
  width: calc(100% - 200px);
  display: flex;
  flex-flow: nowrap;
}
.status-page .status-body .status-line .status-line-sections .section {
  text-align: center;
  position: relative;
}
.status-page .status-body .status-line .status-line-sections .section .material-icons {
  opacity: 1 !important;
  font-size: 20px;
  color: rgba(146, 26, 28, 0.6);
  background: rgba(146, 26, 28, 0.1);
  border-radius: 50%;
  width: 35px;
  height: 35px;
  line-height: 31px;
  box-shadow: 0 0 8px -2px rgba(33, 33, 33, 0.15);
  margin: 5px 0;
  border: 2px solid transparent;
}
.status-page .status-body .status-line .status-line-sections .section .material-icons.white {
  background: #ffffff;
  color: #555555;
  border-color: #555555;
}
.status-page .status-body .status-line .status-line-sections .section .material-icons.red {
  background: #f6cecf;
  color: #db3739;
  border-color: #db3739;
}
.status-page .status-body .status-line .status-line-sections .section .material-icons.green {
  background: #ebf3da;
  color: #a2c754;
  border-color: #a2c754;
}
.status-page .status-body .status-line .status-line-sections .section:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  right: 100%;
  transform: translateY(-50%);
  height: 20px;
  background: #a2c754;
  z-index: -1;
  transition: all 0.2s linear;
}
.status-page .status-body .status-line .status-line-sections .section:nth-child(0):after {
  transition-delay: -0.2s;
}
.status-page .status-body .status-line .status-line-sections .section:nth-child(1):after {
  transition-delay: 0s;
}
.status-page .status-body .status-line .status-line-sections .section:nth-child(2):after {
  transition-delay: 0.2s;
}
.status-page .status-body .status-line .status-line-sections .section:nth-child(3):after {
  transition-delay: 0.4s;
}
.status-page .status-body .status-line .status-line-sections .section:nth-child(4):after {
  transition-delay: 0.6s;
}
.status-page .status-body .status-line .status-line-sections .section:nth-child(5):after {
  transition-delay: 0.8s;
}
.status-page .status-body .status-line .status-line-sections .section:nth-child(6):after {
  transition-delay: 1s;
}
.status-page .status-body .status-line .status-line-sections .section:nth-child(7):after {
  transition-delay: 1.2s;
}
.status-page .status-body .status-line .status-line-sections .section:nth-child(8):after {
  transition-delay: 1.4s;
}
.status-page .status-body .status-line .status-line-sections .section:nth-child(9):after {
  transition-delay: 1.6s;
}
.status-page .status-body .status-line .status-line-sections .section:nth-child(10):after {
  transition-delay: 1.8s;
}
.status-page .status-body .status-line .status-line-sections .section:nth-child(11):after {
  transition-delay: 2s;
}
.status-page .status-body .status-line .status-line-sections .section:nth-child(12):after {
  transition-delay: 2.2s;
}
.status-page .status-body .status-line .status-line-sections .section:nth-child(13):after {
  transition-delay: 2.4s;
}
.status-page .status-body .status-line .status-line-sections .section:nth-child(14):after {
  transition-delay: 2.6s;
}
.status-page .status-body .status-line .status-line-sections .section:nth-child(15):after {
  transition-delay: 2.8s;
}
.status-page .status-body .status-line .status-line-sections .section:first-child:after {
  left: 50% !important;
  right: 50%;
}
.status-page .status-body .status-line .status-line-sections .section-analysis {
  width: 150px !important;
}
.status-page .status-body .status-line .status-line-sections .section-analysis > a {
  display: none;
}
.status-page .status-body .status-line .status-line-sections .section-analysis .section-artefacts {
  height: 35px;
  border: 2px solid transparent;
  margin: 5px 0;
  border-radius: 200px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-around;
  padding: 5px 0;
}
.status-page .status-body .status-line .status-line-sections .section-analysis .section-artefacts .material-icons {
  font-size: 16px;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  line-height: 25px;
  border: none !important;
}
.status-page .status-body .status-line .status-line-sections .section-analysis .section-artefacts .material-icons {
  box-shadow: none;
  background: none !important;
}
.status-page .status-body .status-line .status-line-sections .section-analysis .white + .section-artefacts {
  background: #ffffff;
  color: #555555;
  border-color: #555555;
}
.status-page .status-body .status-line .status-line-sections .section-analysis .red + .section-artefacts {
  background: #f6cecf;
  color: #db3739;
  border-color: #db3739;
}
.status-page .status-body .status-line .status-line-sections .section-analysis .green + .section-artefacts {
  background: #ebf3da;
  color: #a2c754;
  border-color: #a2c754;
}
.status-page .status-body .status-line .status-line-sections.state-0.animate :nth-child(1):after {
  right: 50%;
}
.status-page .status-body .status-line .status-line-sections.state-0.animate :nth-child(2):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-0.animate :nth-child(3):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-0.animate :nth-child(4):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-0.animate :nth-child(5):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-0.animate :nth-child(6):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-0.animate :nth-child(7):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-0.animate :nth-child(8):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-0.animate :nth-child(9):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-0.animate :nth-child(10):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-0.animate :nth-child(11):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-0.animate :nth-child(12):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-0.animate :nth-child(13):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-0.animate :nth-child(14):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-0.animate :nth-child(15):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-0.animate :nth-child(16):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-1.animate :nth-child(1):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-1.animate :nth-child(2):after {
  right: 50%;
}
.status-page .status-body .status-line .status-line-sections.state-1.animate :nth-child(3):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-1.animate :nth-child(4):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-1.animate :nth-child(5):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-1.animate :nth-child(6):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-1.animate :nth-child(7):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-1.animate :nth-child(8):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-1.animate :nth-child(9):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-1.animate :nth-child(10):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-1.animate :nth-child(11):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-1.animate :nth-child(12):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-1.animate :nth-child(13):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-1.animate :nth-child(14):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-1.animate :nth-child(15):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-1.animate :nth-child(16):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-2.animate :nth-child(1):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-2.animate :nth-child(2):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-2.animate :nth-child(3):after {
  right: 50%;
}
.status-page .status-body .status-line .status-line-sections.state-2.animate :nth-child(4):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-2.animate :nth-child(5):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-2.animate :nth-child(6):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-2.animate :nth-child(7):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-2.animate :nth-child(8):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-2.animate :nth-child(9):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-2.animate :nth-child(10):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-2.animate :nth-child(11):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-2.animate :nth-child(12):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-2.animate :nth-child(13):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-2.animate :nth-child(14):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-2.animate :nth-child(15):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-2.animate :nth-child(16):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-3.animate :nth-child(1):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-3.animate :nth-child(2):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-3.animate :nth-child(3):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-3.animate :nth-child(4):after {
  right: 50%;
}
.status-page .status-body .status-line .status-line-sections.state-3.animate :nth-child(5):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-3.animate :nth-child(6):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-3.animate :nth-child(7):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-3.animate :nth-child(8):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-3.animate :nth-child(9):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-3.animate :nth-child(10):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-3.animate :nth-child(11):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-3.animate :nth-child(12):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-3.animate :nth-child(13):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-3.animate :nth-child(14):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-3.animate :nth-child(15):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-3.animate :nth-child(16):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-4.animate :nth-child(1):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-4.animate :nth-child(2):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-4.animate :nth-child(3):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-4.animate :nth-child(4):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-4.animate :nth-child(5):after {
  right: 50%;
}
.status-page .status-body .status-line .status-line-sections.state-4.animate :nth-child(6):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-4.animate :nth-child(7):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-4.animate :nth-child(8):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-4.animate :nth-child(9):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-4.animate :nth-child(10):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-4.animate :nth-child(11):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-4.animate :nth-child(12):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-4.animate :nth-child(13):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-4.animate :nth-child(14):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-4.animate :nth-child(15):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-4.animate :nth-child(16):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-5.animate :nth-child(1):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-5.animate :nth-child(2):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-5.animate :nth-child(3):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-5.animate :nth-child(4):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-5.animate :nth-child(5):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-5.animate :nth-child(6):after {
  right: 50%;
}
.status-page .status-body .status-line .status-line-sections.state-5.animate :nth-child(7):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-5.animate :nth-child(8):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-5.animate :nth-child(9):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-5.animate :nth-child(10):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-5.animate :nth-child(11):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-5.animate :nth-child(12):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-5.animate :nth-child(13):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-5.animate :nth-child(14):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-5.animate :nth-child(15):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-5.animate :nth-child(16):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-6.animate :nth-child(1):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-6.animate :nth-child(2):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-6.animate :nth-child(3):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-6.animate :nth-child(4):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-6.animate :nth-child(5):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-6.animate :nth-child(6):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-6.animate :nth-child(7):after {
  right: 50%;
}
.status-page .status-body .status-line .status-line-sections.state-6.animate :nth-child(8):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-6.animate :nth-child(9):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-6.animate :nth-child(10):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-6.animate :nth-child(11):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-6.animate :nth-child(12):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-6.animate :nth-child(13):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-6.animate :nth-child(14):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-6.animate :nth-child(15):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-6.animate :nth-child(16):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-7.animate :nth-child(1):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-7.animate :nth-child(2):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-7.animate :nth-child(3):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-7.animate :nth-child(4):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-7.animate :nth-child(5):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-7.animate :nth-child(6):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-7.animate :nth-child(7):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-7.animate :nth-child(8):after {
  right: 50%;
}
.status-page .status-body .status-line .status-line-sections.state-7.animate :nth-child(9):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-7.animate :nth-child(10):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-7.animate :nth-child(11):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-7.animate :nth-child(12):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-7.animate :nth-child(13):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-7.animate :nth-child(14):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-7.animate :nth-child(15):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-7.animate :nth-child(16):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-8.animate :nth-child(1):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-8.animate :nth-child(2):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-8.animate :nth-child(3):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-8.animate :nth-child(4):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-8.animate :nth-child(5):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-8.animate :nth-child(6):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-8.animate :nth-child(7):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-8.animate :nth-child(8):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-8.animate :nth-child(9):after {
  right: 50%;
}
.status-page .status-body .status-line .status-line-sections.state-8.animate :nth-child(10):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-8.animate :nth-child(11):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-8.animate :nth-child(12):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-8.animate :nth-child(13):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-8.animate :nth-child(14):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-8.animate :nth-child(15):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-8.animate :nth-child(16):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-9.animate :nth-child(1):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-9.animate :nth-child(2):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-9.animate :nth-child(3):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-9.animate :nth-child(4):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-9.animate :nth-child(5):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-9.animate :nth-child(6):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-9.animate :nth-child(7):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-9.animate :nth-child(8):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-9.animate :nth-child(9):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-9.animate :nth-child(10):after {
  right: 50%;
}
.status-page .status-body .status-line .status-line-sections.state-9.animate :nth-child(11):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-9.animate :nth-child(12):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-9.animate :nth-child(13):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-9.animate :nth-child(14):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-9.animate :nth-child(15):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-9.animate :nth-child(16):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-10.animate :nth-child(1):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-10.animate :nth-child(2):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-10.animate :nth-child(3):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-10.animate :nth-child(4):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-10.animate :nth-child(5):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-10.animate :nth-child(6):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-10.animate :nth-child(7):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-10.animate :nth-child(8):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-10.animate :nth-child(9):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-10.animate :nth-child(10):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-10.animate :nth-child(11):after {
  right: 50%;
}
.status-page .status-body .status-line .status-line-sections.state-10.animate :nth-child(12):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-10.animate :nth-child(13):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-10.animate :nth-child(14):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-10.animate :nth-child(15):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-10.animate :nth-child(16):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-11.animate :nth-child(1):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-11.animate :nth-child(2):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-11.animate :nth-child(3):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-11.animate :nth-child(4):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-11.animate :nth-child(5):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-11.animate :nth-child(6):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-11.animate :nth-child(7):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-11.animate :nth-child(8):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-11.animate :nth-child(9):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-11.animate :nth-child(10):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-11.animate :nth-child(11):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-11.animate :nth-child(12):after {
  right: 50%;
}
.status-page .status-body .status-line .status-line-sections.state-11.animate :nth-child(13):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-11.animate :nth-child(14):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-11.animate :nth-child(15):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-11.animate :nth-child(16):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-12.animate :nth-child(1):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-12.animate :nth-child(2):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-12.animate :nth-child(3):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-12.animate :nth-child(4):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-12.animate :nth-child(5):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-12.animate :nth-child(6):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-12.animate :nth-child(7):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-12.animate :nth-child(8):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-12.animate :nth-child(9):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-12.animate :nth-child(10):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-12.animate :nth-child(11):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-12.animate :nth-child(12):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-12.animate :nth-child(13):after {
  right: 50%;
}
.status-page .status-body .status-line .status-line-sections.state-12.animate :nth-child(14):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-12.animate :nth-child(15):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-12.animate :nth-child(16):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-13.animate :nth-child(1):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-13.animate :nth-child(2):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-13.animate :nth-child(3):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-13.animate :nth-child(4):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-13.animate :nth-child(5):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-13.animate :nth-child(6):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-13.animate :nth-child(7):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-13.animate :nth-child(8):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-13.animate :nth-child(9):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-13.animate :nth-child(10):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-13.animate :nth-child(11):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-13.animate :nth-child(12):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-13.animate :nth-child(13):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-13.animate :nth-child(14):after {
  right: 50%;
}
.status-page .status-body .status-line .status-line-sections.state-13.animate :nth-child(15):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-13.animate :nth-child(16):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-14.animate :nth-child(1):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-14.animate :nth-child(2):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-14.animate :nth-child(3):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-14.animate :nth-child(4):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-14.animate :nth-child(5):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-14.animate :nth-child(6):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-14.animate :nth-child(7):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-14.animate :nth-child(8):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-14.animate :nth-child(9):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-14.animate :nth-child(10):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-14.animate :nth-child(11):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-14.animate :nth-child(12):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-14.animate :nth-child(13):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-14.animate :nth-child(14):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-14.animate :nth-child(15):after {
  right: 50%;
}
.status-page .status-body .status-line .status-line-sections.state-14.animate :nth-child(16):after {
  right: 100%;
}
.status-page .status-body .status-line .status-line-sections.state-15.animate :nth-child(1):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-15.animate :nth-child(2):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-15.animate :nth-child(3):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-15.animate :nth-child(4):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-15.animate :nth-child(5):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-15.animate :nth-child(6):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-15.animate :nth-child(7):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-15.animate :nth-child(8):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-15.animate :nth-child(9):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-15.animate :nth-child(10):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-15.animate :nth-child(11):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-15.animate :nth-child(12):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-15.animate :nth-child(13):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-15.animate :nth-child(14):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-15.animate :nth-child(15):after {
  right: 0;
}
.status-page .status-body .status-line .status-line-sections.state-15.animate :nth-child(16):after {
  right: 50%;
}
.status-page .status-body .status-line .status-line-sections.state-0 :nth-child(1):after {
  right: 50% !important;
}
.status-page .status-body .status-line .status-line-sections.complete.animate :last-child:after {
  right: 50% !important;
}
.status-page .status-body-error {
  display: none;
  font-family: "Montserrat", sans-serif;
  color: #888888;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  padding: 50px 10px 30px;
}

section.note__section {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 1em;
}
section.note__section article {
  width: 100%;
}
section.note__section article a {
  height: 45px;
}
section.note__section article button {
  justify-content: center;
  height: 45px;
  margin-bottom: 10px;
}
section.note__section article button span {
  position: absolute;
  left: 85%;
}
section.note__section article button.button {
  background-color: #555555;
}
section.note__section article button.button .material-icons {
  font-size: 25px;
}