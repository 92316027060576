// Label
label {
    font-size: 16px;
    font-weight: 400;

    color: $black;

    margin-bottom: 6px;

    display: inline-block;

    em {
        white-space: nowrap;
    }
}

.required {
    color: $red;
    margin-right: 6px;
}

// Inputs génériques
input[type="text"],
input[type="password"], {
    font-size: 16px;
    font-weight: 400;
    padding: 10px 14px;

    color: $black;
    background: $white !important;
    border: 1px solid $lightgrey;
    // border-radius: $radius;

    box-sizing: border-box;
    width: 100%;

    &::placeholder {
        color: rgba($lightgrey, 0.5);
    }
}

input[type="checkbox"] + label,
input[type="radio"] + label {
    font-weight: 700;
    font-size: 14px;

    margin-left: 12px;
}

.detail-box {
    width: 100%;
    margin-top: 4px;

    label {
        font-size: 14px;

        display: inline-block;
    }

    input {
        font-size: 14px;

        height: 80px;
        line-height: 30px;
    }
}

// Boutons
form button, a.button, button.button, .ant-btn {
    appearance: none;
    outline: none !important;

    font-size: 16px;
    font-weight: 700 !important;
    font-family: $fontTitle;

    color: $white;
    background: $mc;
    border: none;
    border-radius: $radius;
    cursor: pointer;
    transition: all 0.2s ease;

    padding: 10px 24px;
    width: auto;

    display: flex;
    align-items: center;

    i {
        margin: 0 6px;
    }

    @include icon {
        font-size: 14px;
    }

    &:hover {
        opacity: 0.8;
        color: $white;
    }

    &.alt {
        background: $lightgrey !important;
    }

    &.green {
        background: $green !important;
    }

    // Bouton ghost
    @at-root .ant-btn:not(.ant-btn-primary),
    &.ghost {
        background: transparent !important;
        border-color: transparent !important;
        color: $lightgrey;

        &.slim {
            padding-left: 16px;
            padding-right: 16px;
            font-size: 14px;
        }

        &:hover {
            opacity: 0.8;
        }
    }

    // Bouton disabled
    &:disabled,
    &.disabled {
        background: $lightgrey !important;
        opacity: 0.3 !important;
        cursor: not-allowed;
    }
}

.button-link {
    font-weight: 700;
    font-family: $fontTitle;

    background: rgba($lightgrey, 0.1);
    color: $lightgrey;
    border-radius: $radius;
    cursor: pointer;
    transition: all 0.2s ease;

    padding: 10px 20px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    @include icon {
        font-size: 16px;
        margin-left: 4px;
    }

    &:hover {
        color: $lightgrey;
        opacity: 0.8;
    }
}

// Contenant des inputs
form p, form div.ctn {
    width: 100%;
    margin-bottom: 16px;

    display: flex;
    flex-flow: column wrap;
    align-items: flex-start;
}

// Erreurs
small.error {
    font-size: 14px;
    font-weight: 700;
    color: $red;

    margin-top: 12px;
    display: inline-block;
}

.error-wrap .ant-input,
.error-wrap .ant-select-selection,
.error-wrap input,
.error-input,
.error-input .ant-select-selection {
    border-color: $red !important;
}
