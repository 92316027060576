@mixin icon {
    .material-icons {
        @content;
    }
}

@mixin clearfix {
    &::after {
        content: "";
        clear: both;
        display: table;
    }
}

@mixin mobile {
    @media only screen and (max-width: 820px) {
        @content;
    }
}

@mixin not-mobile {
    @media only screen and (min-width: 820px) {
        @content;
    }
}

@mixin large {
    @media only screen and (min-width: 1540px) {
        @content;
    }
}

@mixin dashed-line($c) {
    position: relative;

    &:after {
        content: '';
        z-index: -1;

        width: 100%;
        height: 10px;

        display: block;

        position: absolute;
        bottom: 0;
        right: 0; left: 0;

        background: repeating-linear-gradient(
            -45deg,
            transparent 0px,
            transparent 6px,
            $c 6px,
            $c 8px
        );
    }
}

@mixin help-icon {
    @include icon {
        font-size: 16px;

        transition: all 0.2s ease;
        color: $lightgrey;
        cursor: help;

        margin-left: 8px;
        transform: translateY(3px);

        &:hover {
            opacity: 0.8;
        }
    }
}

@mixin tag-item {
    font-size: 12px;
    font-weight: 700;

    color: $grey;
    background: rgba($lightgrey, 0.2);
    border-radius: $radius;
    box-shadow: $bsSmallerItem;

    padding: 4px 20px;
    &:not(:last-of-type) {
        margin-right: 6px;
    }

    &:empty,
    > *:empty {
        display: none;
    }
}
